import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const Header = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    Cookies.remove("accessToken");
    console.log("User logged out, token removed.");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <nav className="py-2 text-white bg-pink-500 md:py-3">
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
          {/* Logo - Left side */}
          <div className="flex items-center space-x-2">
            <NavLink to="/home">
              <img src="assets/Garmentlinelogo.png" alt="Logo" className="h-8 w-auto mr-4" />
            </NavLink>
          </div>

          {/* Navigation Links - Centered */}
          <ul className="flex flex-col space-y-2 text-center sm:flex-row sm:space-y-0 sm:space-x-6 sm:text-left">
            <li className="sm:ml-8">
              <NavLink to="/home" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                <span className="mr-1 text-xl">HOME</span>
              </NavLink>
            </li>
            <li className="sm:ml-8">
              <NavLink to="/garmentline" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                GARMENTLINE MAGAZINE
              </NavLink>
            </li>
            <li className="sm:ml-8">
              <NavLink to="/innerfashion" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                INNERFASHION MAGAZINE
              </NavLink>
            </li>
            <li>
              <NavLink to="/subscribe" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                SUBSCRIBE NOW
              </NavLink>
            </li>
            <li>
              <NavLink to="/event" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                EVENT
              </NavLink>
            </li>
            <li>
              <NavLink to="/refund-policy" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                REFUND POLICY
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" className={({ isActive }) => (isActive ? 'text-black hover:underline' : 'hover:underline')}>
                BLOG
              </NavLink>
            </li>
          </ul>

          {/* Login/Logout Button - Right side */}
          <div className="flex ml-auto space-x-3">
            {isLoggedIn ? (
              <button
                className="text-white hover:underline"
                onClick={handleLogout}>
                Logout
              </button>
            ) : (
              <button
                className="text-white hover:underline"
                onClick={handleLogin}>
                LOGIN
              </button>
            )}
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative w-full bg-center bg-cover h-96" style={{ backgroundImage: 'url(assets/GARMENT.png)', height: '220px' }}>
        <div className="container relative flex items-center justify-center h-full px-4 mx-auto text-center md:px-12">
          <div>
            <h1 className="text-4xl font-bold text-pink-500 md:text-4xl">
              Connect Media
            </h1>
            <p className="mt-2 text-sm text-white md:text-lg">
              GARMENT (MANUFACTURE, WHOLESALE & RETAIL) INDUSTRY MAGAZINE
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
