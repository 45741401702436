import React from 'react';
import ConnectMediaHeader from '../ConnectMediaHeader/ConnectMediaHeader';
import Footer from '../../GarmentLine/Footer/Footer';

const ConnectTermsCondition = () => {
  return (
    <div className="App">
      <ConnectMediaHeader />
      <main className="container mx-auto py-8 px-4">
        <h2 className="text-3xl font-bold text-left mb-6 ml-6">Privacy Policy</h2>
        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Term and Conditions</strong> – Welcome to Garmentline.live, our digital/online b2b weekly e-paper & monthly magazine on the Business of Garment manufacturers, wholesale & retail industry that cover Domestic Brand Manufacturers, wholesalers, Retailers & other industry updates.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          Below are our terms and conditions for use of the site, which you may access in several ways, including the World Wide Web, PDA, Mobile phone, digital television, and RSS feeds. These terms and conditions apply whenever you access the site, on whatever devices. By using the site as a reader or registered user, you are deemed to have accepted these terms and conditions. If you are registered as a user, please read the privacy policy carefully.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          Any changes we make to the terms and conditions will be reflected on this page. Access to and use of this site Connect Media is provided by Garmentline.live subject to the following terms:
        </p>

        <ul className="list-decimal pl-8 text-lg mb-8 ml-6 mr-6">
          <li className="mb-2">
            By using Garmentline.live you agree to be legally bound by these terms, which shall take effect immediately on your first use of Garmentline.live. If you do not agree to be legally bound by all the following terms, please do not access and/or use Garmentline.live.
          </li>
          <li className="mb-2">
            Connect Media (Garmentline.live) may change these terms at any time by posting changes online. Please review these terms regularly to ensure you are aware of any changes made by Connect Media (Garmentline.live).
          </li>
          <li className="mb-2">
            You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use garmentline.live content in any way except for your own personal, non-commercial use. You also agree not to adapt, alter or create a derivative work from any garmentline.live content except for your own personal, non-commercial use. Any other use of garmentline.live content requires the prior written permission of Connect Media.
          </li>
          <li className="mb-2">
            You agree to use garmentline.live only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else’s use and enjoyment of garmentline.live. Prohibited behavior includes harassing or causing distress or inconvenience to any person, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within garmentline.live.
          </li>
        </ul>

        <h4 className="text-xl font-bold text-left mb-6 ml-6">Disclaimer of Liability</h4>
        <p className="text-lg mb-4 ml-6 mr-6">
          Garmentline.live does not accept any responsibility for any statement in the material. You must not rely on any statement we have published on garmentline.live without first taking specialist professional advice. Nothing in the material is provided for any specific purpose or at the request of any particular person.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          We give no warranties of any kind and without any representations for material—information, names, images, pictures, logos and icons—found in garmentline.live.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          You can access other sites via links from garmentline.live. These sites are not under our control, and we are not responsible in any way for any of their contents.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          Garmentline.live is not liable for any of the following losses or damages (whether such losses were foreseen, foreseeable, known, or otherwise):
        </p>
        <ul className="list-disc pl-8 text-lg mb-8 ml-6 mr-6">
          <li>Loss of data;</li>
          <li>Loss of revenue or anticipated profits;</li>
          <li>Loss of business;</li>
          <li>Loss of opportunity;</li>
          <li>Loss of goodwill or injury to reputation;</li>
          <li>Losses suffered by third parties; or</li>
          <li>Any indirect, consequential, special or exemplary damages arising from the use of garmentline.live regardless of the form of action.</li>
        </ul>
        <p className="text-lg mb-4 ml-6 mr-6">
          You must take your own precautions in this respect, as we accept no responsibility for any infection by virus, by other contamination, or by anything that has destructive properties.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6 text-gray-600">
          <strong>If you’re under 18:</strong>
        </p>
        <ul className="list-disc pl-8 text-lg mb-8 ml-6 mr-6">
          <li>Please get permission from a parent or guardian before taking part in any garmentline.live activities.</li>
          <li>
            Never reveal any personal information about yourself or anyone else (for example, telephone number, home address, or email address).
            <h4 className="text-xl font-bold text-left mb-6 ml-6 text-gray-600">Third Party Material on Garmentline.live</h4>
            <p className="text-lg mb-4 ml-6 mr-6">
              You will see advertising material submitted by third parties on garmentline.live. Advertisers are solely responsible for the content of advertising material, which they submit to us, including ensuring that it complies with relevant legislation. We accept no responsibility for the content of advertising material, including, without limitation, any error, omission, or inaccuracy therein.
            </p>
          </li>
        </ul>
        <h4 className="text-xl font-bold text-left mb-6 ml-6">Graphic Material Submission to Garmentline.Live</h4>
        <p className="text-lg mb-4 ml-6 mr-6">
               When you send a photograph or other graphical material to us, you do so in accordance with these Terms of Use. This means that you hereby agree that you have taken the photograph(s) you have sent to us or you have permission from or are authorized by the owner of the photograph(s) to send it (them) to us, and you are granting us a non-exclusive, royalty-free license to publish or otherwise use the photograph(s) in any way and at any time we want on garmentline.live. The photograph(s) must not be defamatory and may not break any laws.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
                Selected photographs and graphical material will be published at the discretion of the editor and you will not be paid, even if your photograph(s) is (are) published.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
              We may cut, edit, crop, or arrange your photograph(s) or graphic as we think fit to appear on garmentline.live, and we may remove your photograph(s) or graphics at any time.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
               Your name will be published alongside your photograph(s) or graphic, but we may edit or delete any comments that you submit along with your photograph(s) or graphic.
        </p>

        <h4 className="text-xl font-bold text-left mb-6 ml-6">Safety</h4>
        <p className="text-lg mb-4 ml-6 mr-6">
              We advise that you never reveal any personal information about yourself or anyone else (for example: telephone number, home address or email address). Please do not include postal addresses of any kind. If you have a helpful address to share, inform the host of the relevant community area using the ‘Contact Us’ link and they will promote it if they see fit.
        </p>
        <h4 className="text-xl font-bold text-left mb-6 ml-6">General</h4>
            <p className="text-lg mb-4 ml-6 mr-6">
              These terms may vary from time to time. Please ensure that you review these terms and conditions regularly as you will be deemed to have accepted any variation if you continue to use the site after it has been posted.
           </p>
           <p className="text-lg mb-4 ml-6 mr-6">
           Although we will do our best to provide constant, uninterrupted access to www.garmentline.live, we do not guarantee this. We accept no responsibility or liability for any interruption or delay.
          </p>
          <h4 className="text-xl font-bold text-left mb-6 ml-6">Governing Law & Jurisdiction</h4>
           <p className="text-lg mb-4 ml-6 mr-6">
           These terms shall be governed by and interpreted in accordance with the laws of India and especially the Information Technology Act, 2000. All relevant rules, regulations, directions, orders and notifications will also apply.
           </p>
           <p className="text-lg mb-4 ml-6 mr-6">
           By choosing to browse this site, you agree to the site’s Privacy Policy
          </p>
        </main>
        <Footer/>
    </div>
  );
};

export default ConnectTermsCondition;
