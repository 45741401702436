import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../GarmentLine/Footer/Footer';
import Cookies from 'js-cookie'; 
import ConnectMediaHeader from '../ConnectMediaHeader/ConnectMediaHeader';

const SubscribeNow = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    address: '',
    email: '',
    mobileNumber: '',
    typeOfBusiness: '',
    businessOfGarmentFashion: {
      period: '',
      numberOfIssues: '',
      totalCharge: '',
    },
    businessOfInnerFashion: {
      period: '',
      numberOfIssues: '',
      totalCharge: '',
    },
    garmentLineAndInnerFashion: {
      period: '',
      numberOfIssues: '',
      totalCharge: '',
      interested: '',
    }
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id.includes('businessOfGarmentFashion') || id.includes('businessOfInnerFashion') || id.includes('garmentLineAndInnerFashion')) {
      const section = id.split('.')[0]; // split based on object structure
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [id.split('.')[1]]: value, // Update the correct field
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = Object.values(formData).every((field) => {
      if (typeof field === 'string') return field.trim() !== '';
      if (typeof field === 'object') {
        return Object.values(field).every((nestedField) => nestedField.trim() !== '');
      }
      return true;
    });

    if (!isFormValid) {
      alert('Please fill in all required fields.');
      return;
    }
    const token = Cookies.get('accessToken');
    if (!token) {
      alert('You are not authorized. Please log in.');
      return;
    }
    try {
      const response = await fetch('https://garment-line-backend-0pol.onrender.com/apis/user/sendSubscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,  // Add Bearer token to header
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setPopupVisible(true);  // Show success popup
        setIsSubmitted(true);
        setTimeout(() => {
          setPopupVisible(false);  // Hide popup after 3 seconds
          setFormData({   // Reset form fields
            name: '',
            companyName: '',
            address: '',
            email: '',
            mobileNumber: '',
            typeOfBusiness: '',
            businessOfGarmentFashion: { period: '', numberOfIssues: '', totalCharge: '' },
            businessOfInnerFashion: { period: '', numberOfIssues: '', totalCharge: '' },
            garmentLineAndInnerFashion: { period: '', numberOfIssues: '', totalCharge: '', interested: '' }
          });
        }, 3000);  // Popup duration of 3 seconds
        console.log('Form successfully submitted:', formData);
      } else {
        alert('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      alert('An error occurred while submitting the form. Please try again later.');
      console.error('Error:', error);
    }
  };
  return (
    <div className="App">
      <ConnectMediaHeader />
      <div className="mt-8 mb-4 mx-8">
        <p className="text-xl font-semibold ml-8">Respected Madam / Sir,</p>
        <p className="mt-4 text-lg ml-8 mr-8">
          It gives us immense pleasure to present our prestigious business-to-business e-paper (weekly) and Magazine monthly titles –
          {/* <Link to="/connect-media" className="text-orange-500 hover:underline">‘GARMENTLINE’</Link> from */}
          <Link to="/connect-media" className="text-orange-500 hover:underline">‘Connect Media’</Link>.
        </p>
        <p className="mt-4 text-lg ml-8 mr-8">
          <span className="font-semibold text-gray-500">Connect Media is a weekly e-paper & monthly magazine</span> on the Business of GARMENT manufacturers, wholesale & <span className="font-semibold">retail</span> Industry (from Manufacturer to Retailer) covering industry updates & industry day-to-day events and up-and-coming trends. Subscribe Now,
        </p>
        <p className="mt-4 text-lg ml-8 mr-8">
          Pleased to have you as our valuable Subscriber of our <span className="font-semibold">e-paper weekly</span> & Monthly magazine. We present to you our best Subscription tariff offer.
        </p>
        <p className="mt-4 text-lg ml-8 mr-8">
          Do find attached a Subscription Form to be filled in, send across at the earliest.
        </p>
        <p className="text-2xl font-semibold mt-8 ml-8">Subscription Form</p>
         {/* Success Popup */}
         {popupVisible && (
          <div className="fixed inset-0 flex justify-center items-center z-50 bg-opacity-50 bg-gray-700">
            <div className="bg-green-500 text-white p-6 rounded-md shadow-lg">
              <p className="text-xl font-bold">Form Successfully Submitted!</p>
            </div>
          </div>
        )}
        <form className="mt-8 ml-8 mr-8" onSubmit={handleSubmit}>
          <div className="flex space-x-8">
            <div className="w-1/2">
              <label htmlFor="name" className="block font-semibold mb-2">Contact Person</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                placeholder="Enter contact person's name"
                required
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="companyName" className="block font-semibold mb-2">Company Name</label>
              <input
                type="text"
                id="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                placeholder="Enter company name" required
              />
            </div>
          </div>
          <div className="mt-8">
            <label htmlFor="address" className="block font-semibold mb-2">Address</label>
            <textarea
              id="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
              placeholder="Enter your address" required
              rows="2"
            />
          </div>
          <div className="flex space-x-8 mt-8">
            <div className="w-1/2">
              <label htmlFor="email" className="block font-semibold mb-2">Email ID</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                placeholder="Enter your email" required
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="mobileNumber" className="block font-semibold mb-2">Mobile No.</label>
              <input
                type="text"
                id="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                placeholder="Enter your mobile number" required
              />
            </div>
          </div>
          <div className="flex space-x-8 mt-8">
            <div className="w-1/2">
              <label htmlFor="typeOfBusiness" className="block font-semibold mb-2">Type of Business</label>
              <select
                id="typeOfBusiness"
                value={formData.typeOfBusiness}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
              >
                <option value="" disabled>Choose from list</option>
                <option value="Garment Manufacturer">Garment Manufacturer</option>
                <option value="Wholesaler">Wholesaler</option>
                <option value="Agent/Distributor">Agent/Distributor</option>
                <option value="others">Other</option>
              </select>
            </div>
          </div>

          {/* GARMENT LINE (Optional) */}
          <div className="mt-8">
            <p className="text-xl font-semibold mb-4">GARMENT LINE (optional)<br />
              <span className="block text-gray-500 text-sm">BUSINESS OF GARMENT FASHION</span>
            </p>
            <div className="flex space-x-8">
              <div className="w-1/3">
                <label htmlFor="businessOfGarmentFashion.period" className="block font-semibold mb-2">Period (Years) (optional)</label>
                <select
                  id="businessOfGarmentFashion.period"
                  value={formData.businessOfGarmentFashion.period}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select period</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="businessOfGarmentFashion.numberOfIssues" className="block font-semibold mb-2">No. of Issues (optional)</label>
                <select
                  id="businessOfGarmentFashion.numberOfIssues"
                  value={formData.businessOfGarmentFashion.numberOfIssues}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="businessOfGarmentFashion.totalCharge" className="block font-semibold mb-2">Total Including Courier Charge (optional)</label>
                <select
                  id="businessOfGarmentFashion.totalCharge"
                  value={formData.businessOfGarmentFashion.totalCharge}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select total</option>
                  <option value="3300">3300</option>
                  <option value="5000">5000</option>
                </select>
              </div>
            </div>
          </div>

          {/* INNER FASHION (Optional) */}
          <div className="mt-8">
            <p className="text-xl font-semibold mb-4">INNER FASHION (optional)<br />
              <span className="block text-gray-500 text-sm">BUSINESS OF INNER FASHION</span>
            </p>
            <div className="flex space-x-8">
              <div className="w-1/3">
                <label htmlFor="businessOfInnerFashion.period" className="block font-semibold mb-2">Period (Years) (optional)</label>
                <select
                  id="businessOfInnerFashion.period"
                  value={formData.businessOfInnerFashion.period}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select period</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="businessOfInnerFashion.numberOfIssues" className="block font-semibold mb-2">No. of Issues (optional)</label>
                <select
                  id="businessOfInnerFashion.numberOfIssues"
                  value={formData.businessOfInnerFashion.numberOfIssues}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="businessOfInnerFashion.totalCharge" className="block font-semibold mb-2">Total Including Courier Charge (optional)</label>
                <select
                  id="businessOfInnerFashion.totalCharge"
                  value={formData.businessOfInnerFashion.totalCharge}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select total</option>
                  <option value="3300">2500</option>
                  <option value="5000">3500</option>
                </select>
              </div>
            </div>
          </div>

          {/* COMBINED GARMENT + INNER FASHION (Optional) */}
          <div className="mt-8">
            <p className="text-xl font-semibold mb-4">COMBINED GARMENT + INNER FASHION (optional)<br />
            </p>
            <div className="flex space-x-8">
              <div className="w-1/3">
                <label htmlFor="garmentLineAndInnerFashion.period" className="block font-semibold mb-2">Period (Years) (optional)</label>
                <select
                  id="garmentLineAndInnerFashion.period"
                  value={formData.garmentLineAndInnerFashion.period}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select period</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="garmentLineAndInnerFashion.numberOfIssues" className="block font-semibold mb-2">No. of Issues (optional)</label>
                <select
                  id="garmentLineAndInnerFashion.numberOfIssues"
                  value={formData.garmentLineAndInnerFashion.numberOfIssues}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="garmentLineAndInnerFashion.totalCharge" className="block font-semibold mb-2">Total Including Courier Charge (optional)</label>
                <select
                  id="garmentLineAndInnerFashion.totalCharge"
                  value={formData.garmentLineAndInnerFashion.totalCharge}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
                >
                  <option value="" disabled>Select total</option>
                  <option value="3300">2500</option>
                  <option value="5000">3500</option>
                </select>
              </div>
            </div>
          </div>

          {/* Subscription Interest (Optional) */}
          <div className="mt-8">
            <label htmlFor="garmentLineAndInnerFashion.interested" className="block font-semibold mb-2">YES, I am interested in Subscribing (optional)</label>
            <select
              id="garmentLineAndInnerFashion.interested"
              value={formData.garmentLineAndInnerFashion.interested}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300 bg-white"
            >
              <option value="" disabled>Select subscription type</option>
              <option value="Garmentline">GarmentLine</option>
              <option value="Inner fashion">InnerFashion</option>
              <option value="Garmentline & Inner fashion">Combined (GarmentLine & InnerFashion)</option>
            </select>
          </div>

          <div className="mt-8">
            <button
              type="submit"
              className="bg-green-500 text-white font-semibold px-6 py-3 rounded-md hover:bg-green-600 transition"
            >
              {isSubmitted ? 'Form Submitted' : 'Submit Form'}
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
export default SubscribeNow;
