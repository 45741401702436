import React from 'react';
import Header from '../GarmentLine/Header/Header';
import Footer from '../GarmentLine/Footer/Footer';

const TermsCondition = () => {
  return (
    <div className="App">
      <Header />
      <main className="container mx-auto py-8 px-4">
        <h2 className="text-3xl font-bold text-left mb-6 ml-6">Terms & Condition</h2>
        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Term and Conditions:</strong> Welcome to ConnectMedia.online, a digital platform that provides a B2B weekly e-paper and a monthly magazine focused on the garment manufacturing, wholesale, and retail industries. Our platform covers industry updates, domestic brand manufacturers, wholesalers, retailers, and other relevant news.
            By accessing and using ConnectMedia.online, you agree to comply with the terms and conditions outlined 
            below. These terms apply to all users, whether accessing through a computer, mobile device, or any other digital platform.
        </p>
        <h4 className="text-xl font-bold text-left mb-6 ml-6">Acceptance of Terms</h4>
        <p className="text-lg mb-4 ml-6 mr-6"> By using ConnectMedia.online, you acknowledge and agree to be legally bound by these terms and conditions from your first visit. 
            If you do not accept any part of these terms, please refrain from using the site.
            We reserve the right to update or modify these terms at any time without prior notice. 
            Your continued use of the site after such changes will constitute your acceptance of the updated terms.</p>
        <h4 className="text-xl font-bold text-left mb-6 ml-6">Permitted Use</h4>
        <p className="text-lg mb-4 ml-6 mr-6">
        Users may browse, read, and share content from ConnectMedia.online for personal, non-commercial purposes.<br/>
        However, you may not:<br/>
        <li>Copy, reproduce, or republish content without explicit written permission.</li>
        <li>Modify or create derivative works from any content on the site.</li>
        <li>Use the platform in a way that disrupts or restricts other users.</li>
        <li>Post or transmit any offensive, illegal, or harmful content.</li>
        </p>
        <h4 className="text-xl font-bold text-left mb-6 ml-6">User Conduct</h4>
            <p className="text-lg mb-4 ml-6 mr-6">
            You agree to use ConnnectMedia.online responsibly and in accordance with all applicable laws. 
            Any behavior that could harm, harass, or negatively impact other users, including the transmission of spam, offensive material, or unauthorized advertising, is strictly prohibited.
           </p>
           <h4 className="text-xl font-bold text-left mb-6 ml-6"> Disclaimer of Liability</h4>
           <p className="text-lg mb-4 ml-6 mr-6">
           The information provided on ConnnectMedia.online is for general informational purposes only. 
           While we strive for accuracy, we do not guarantee the completeness or reliability of any content. 
           We are not responsible for any direct or indirect losses arising from reliance on the site's content.
           <br/>
           We are also not liable for:
           <br/>
           <li>Loss of data or revenue.</li>
           <li>Business disruptions or loss of opportunities.</li>
           <li>Any damages resulting from external links or third-party content.</li>
           Users should take necessary precautions to protect their devices from malware and cyber threats.
           </p>
           <h4 className="text-xl font-bold text-left mb-6 ml-6">Third-Party Content and Advertising</h4>
           <p className="text-lg mb-4 ml-6 mr-6">ConnnectMedia.online may display advertisements or third-party content. 
            We do not endorse or take responsibility for any external advertisements, and users interact with such content at their own risk.</p>
            <h4 className="text-xl font-bold text-left mb-6 ml-6">Submissions (Photographs & Graphics)</h4>
            <p className="text-lg mb-4 ml-6 mr-6">If you submit any photographs or graphical content to ConnectMedia.online, you confirm that:<br/>
            <li>You own the rights or have permission from the owner.</li>
               <li> You grant us a royalty-free, non-exclusive license to use and modify the content as needed.</li>
                <li>Your submissions do not violate any laws or contain defamatory material.</li>
                <li>No compensation will be provided for published content.</li>
            </p>
            <h4 className="text-xl font-bold text-left mb-6 ml-6"> Privacy and Safety</h4>
            <p className="text-lg mb-4 ml-6 mr-6"> 
                We prioritize user privacy and advise users not to share personal or sensitive information publicly. 
                For more details on data collection and use, please review our Privacy Policy.
            </p>
            <h4 className="text-xl font-bold text-left mb-6 ml-6">Access and Availability</h4>
            <p className="text-lg mb-4 ml-6 mr-6">
                While we aim for uninterrupted access to ConnnectMedia.online, we do not guarantee that the platform will always be available. 
                We are not responsible for any technical issues or service interruptions. </p>
          <h4 className="text-xl font-bold text-left mb-6 ml-6">Governing Law & Jurisdiction</h4>
           <p className="text-lg mb-4 ml-6 mr-6">
           These terms shall be governed by and interpreted in accordance with the laws of India and especially the Information Technology Act, 2000. All relevant rules, regulations, directions, orders and notifications will also apply.
           </p>
           <p className="text-lg mb-4 ml-6 mr-6">
           By choosing to browse this site, you agree to the site’s Privacy Policy
          </p>
          <h4 className="text-xl font-bold text-left mb-6 ml-6"> Contact Information</h4>
           <p className="text-lg mb-4 ml-6 mr-6">
           For any questions regarding these terms, please contact us through the Contact Us section on our website.
           By continuing to browse and use ConnnectMedia.online, you agree to abide by these terms and conditions.
           </p>
        </main>
        <Footer/>
    </div>
  );
};

export default TermsCondition;
