import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../GarmentLine/Header/Header';
import Footer from '../GarmentLine/Footer/Footer';

const Pricing = () => {
  const navigate = useNavigate();

  const handlePayment = (amount) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      const options = {
        key: 'YOUR_RAZORPAY_KEY', // Replace with your actual Razorpay Key
        amount: amount * 100, // Convert to paisa (₹1 = 100 paisa)
        currency: 'INR',
        name: 'GarmentLine',
        description: 'Subscription Payment',
        handler: function (response) {
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        },
        prefill: {
          name: 'John Doe',
          email: 'johndoe@example.com',
          contact: '9876543210',
        },
        theme: {
          color: '#FFC300',
        },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    };
  };  

  return (
    <div className="App">
      <Header />
      <div className="container mx-auto p-8 text-center mt-12">
      <h1 className="text-4xl font-bold mb-10">Choose a Pricing Plan</h1>
        <div className="flex justify-center space-x-10 ">
          <div className="w-[400px] h-[320px] p-8 border border-gray-300 rounded-xl shadow-xl flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold mb-2">Basic Plan</h2>
            <p className="text-xl font-semibold">₹3000</p>
            <p className="text-sm text-gray-500">(for 1 year)</p>
            {/* <button 
              onClick={() => handlePayment(3000)} 
              className="mt-4 px-6 py-3 bg-blue-500 text-white text-lg rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Pay ₹3000
            </button> */}
          </div>

          {/* Premium Plan */}
          <div className="w-[400px] h-[320px] p-8 border border-gray-300 rounded-xl shadow-xl flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold mb-2">Premium Plan</h2>
            <p className="text-xl font-semibold">₹5000</p>
            <p className="text-sm text-gray-500">(for 2 years)</p>
            {/* <button  
              onClick={() => handlePayment(5000)} 
              className="mt-4 px-6 py-3 bg-blue-500 text-white text-lg rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Pay ₹5000
            </button> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
