import React, { useState } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Cookies from "js-cookie";

const SubscribeNow = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    address: "",
    email: "",
    mobileNumber: "",
    typeOfBusiness: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;

    // Prevent non-numeric characters in mobile number
    if (id === "mobileNumber" && !/^\d*$/.test(value)) return;

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
     const isFormValid = Object.values(formData).every((field) => {
      if (typeof field === 'string') return field.trim() !== '';
      if (typeof field === 'object') {
        return Object.values(field).every((nestedField) => nestedField.trim() !== '');
      }
      return true;
    });
    

    if (!isFormValid) {
      alert('Please fill in all required fields.');
      return;
    }
    // const token = Cookies.get('accessToken');

    // if (!token) {
    //   alert('You are not authorized. Please log in.');
    //   return;
    // } 


    
    // const token = Cookies.get("accessToken");
    // if (!token) {
    //   setError("You are not authorized. Please log in.");
    //   setIsSubmitting(false);
    //   return;
    // }

    try {
      const response = await fetch(
        "https://garment-line-backend-0pol.onrender.com/apis/user/sendSubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setPopupVisible(true);
        setIsSubmitted(true);
        setTimeout(() => {
          setPopupVisible(false);
          setFormData({
            name: "",
            companyName: "",
            address: "",
            email: "",
            mobileNumber: "",
            typeOfBusiness: "",
          });
        }, 3000);
      } else {
        setError("Failed to submit the form. Please try again later.");
      }
    } catch (error) {
      setError("An error occurred while submitting the form. Please try again later.");
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="App">
      <Header />
      <div className="mx-8 mt-8 mb-4">
        <p className="ml-8 text-xl font-semibold">Respected Madam / Sir,</p>
        <p className="mt-4 ml-8 mr-8 text-lg">
          It gives us immense pleasure to present our prestigious business-to-business e-paper (weekly) and Magazine monthly titles –{" "}
          <Link to="/connect-media" className="text-orange-500 hover:underline">‘Connect Media’</Link>.
        </p>
        <p className="mt-4 ml-8 mr-8 text-lg">
          <span className="font-semibold text-gray-500">Connect Media is a weekly e-paper & monthly magazine</span> on the Business of GARMENT manufacturers, wholesale & <span className="font-semibold">retail</span> Industry. Subscribe Now,
        </p>
        <p className="mt-4 ml-8 mr-8 text-lg">
          We present to you our best Subscription tariff offer. Do find attached a Subscription Form to be filled in.
        </p>
        <p className="mt-8 ml-8 text-2xl font-semibold">Subscription Form</p>

        {popupVisible && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="p-6 text-white bg-green-500 rounded-md shadow-lg">
              <p className="text-xl font-bold">Form Successfully Submitted!</p>
              <button
                className="px-4 py-2 mt-2 font-semibold text-green-500 bg-white rounded"
                onClick={() => setPopupVisible(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {error && (
          <div className="p-4 mt-4 text-white bg-red-500 rounded-md">{error}</div>
        )}

        <form className="mt-8 ml-8 mr-8" onSubmit={handleSubmit}>
          <div className="flex space-x-8">
            <div className="w-1/2">
              <label htmlFor="name" className="block mb-2 font-semibold">Contact Person</label>
              <input type="text" id="name" value={formData.name} onChange={handleChange}
                className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300"
                placeholder="Enter contact person's name" required />
            </div>
            <div className="w-1/2">
              <label htmlFor="companyName" className="block mb-2 font-semibold">Company Name</label>
              <input type="text" id="companyName" value={formData.companyName} onChange={handleChange}
                className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300"
                placeholder="Enter company name" required />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="address" className="block mb-2 font-semibold">Address</label>
            <textarea id="address" value={formData.address} onChange={handleChange}
              className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300"
              placeholder="Enter your address" required rows="2" />
          </div>

          <div className="flex mt-8 space-x-8">
            <div className="w-1/2">
              <label htmlFor="email" className="block mb-2 font-semibold">Email ID</label>
              <input type="email" id="email" value={formData.email} onChange={handleChange}
                className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300"
                placeholder="Enter your email" required />
            </div>
            <div className="w-1/2">
              <label htmlFor="mobileNumber" className="block mb-2 font-semibold">Mobile No.</label>
              <input type="text" id="mobileNumber" value={formData.mobileNumber} onChange={handleChange}
                className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300"
                placeholder="Enter your mobile number" required />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="typeOfBusiness" className="block mb-2 font-semibold">Type of Business</label>
            <select id="typeOfBusiness" value={formData.typeOfBusiness} onChange={handleChange}
              className="w-full p-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-green-300">
              <option value="" disabled>Choose from list</option>
              <option value="Garment Manufacturer">Garment Manufacturer</option>
              <option value="Wholesaler">Wholesaler</option>
              <option value="Agent/Distributor">Agent/Distributor</option>
              <option value="others">Other</option>
            </select>
          </div>

          <div className="mt-8">
            <button type="submit" className="px-6 py-3 text-white bg-green-500 rounded-md" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit Form"}
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SubscribeNow;


// import React, { useState } from 'react';
// import Header from '../Header/Header';
// import { Link } from 'react-router-dom';
// import Footer from '../Footer/Footer';
// import Cookies from 'js-cookie'; 

// const SubscribeNow = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     companyName: '',
//     address: '',
//     email: '',
//     mobileNumber: '',
//     typeOfBusiness: ''
//     // businessOfGarmentFashion: {
//     //   period: '',
//     //   numberOfIssues: '',
//     //   totalCharge: '',
//     // },
//     // businessOfInnerFashion: {
//     //   period: '',
//     //   numberOfIssues: '',
//     //   totalCharge: '',
//     // },
//     // garmentLineAndInnerFashion: {
//     //   period: '',
//     //   numberOfIssues: '',
//     //   totalCharge: '',
//     //   interested: '',
//     // }
//   });

//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [popupVisible, setPopupVisible] = useState(false);

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     // if (id.includes('businessOfGarmentFashion') || id.includes('businessOfInnerFashion') || id.includes('garmentLineAndInnerFashion')) {
//     //   const section = id.split('.')[0]; // split based on object structure
//     //   setFormData((prev) => ({
//     //     ...prev,
//     //     [section]: {
//     //       ...prev[section],
//     //       [id.split('.')[1]]: value, // Update the correct field
//     //     },
//     //   }));
//     // } else {
//       setFormData((prev) => ({
//         ...prev,
//         [id]: value,
//       }));
    
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // const isFormValid = Object.values(formData).every((field) => {
//     //   if (typeof field === 'string') return field.trim() !== '';
//     //   if (typeof field === 'object') {
//     //     return Object.values(field).every((nestedField) => nestedField.trim() !== '');
//     //   }
//     //   return true;
//     // });
//     // const isFormValid = Object.entries(formData).every(([key, value]) => {
//     //   if (typeof value === 'string') return value.trim() !== '';
//     //   if (typeof value === 'object') {
//     //     return Object.values(value).every((nestedField) => nestedField.trim() !== '');
//     //   }
//     //   return true;
//     // });
    

//     // if (!isFormValid) {
//     //   alert('Please fill in all required fields.');
//     //   return;
//     // }
//     const token = Cookies.get('accessToken');

//     if (!token) {
//       alert('You are not authorized. Please log in.');
//       return;
//     }

//     try {
//       const response = await fetch('http://garment-line-webapp-env.eba-pcguyaur.ap-south-1.elasticbeanstalk.com/user/sendSubscription', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         setPopupVisible(true);
//         setIsSubmitted(true);
//         setTimeout(() => {
//           setPopupVisible(false);
//           setFormData({
//             name: '',
//             companyName: '',
//             address: '',
//             email: '',
//             mobileNumber: '',
//             typeOfBusiness: '',
//             // businessOfGarmentFashion: { period: '', numberOfIssues: '', totalCharge: '' },
//             // businessOfInnerFashion: { period: '', numberOfIssues: '', totalCharge: '' },
//             // garmentLineAndInnerFashion: { period: '', numberOfIssues: '', totalCharge: '', interested: '' }
//           });
//         }, 3000);
//         console.log('Form successfully submitted:', formData);
//       } else {
//         alert('Failed to submit the form. Please try again later.');
//       }
//     } catch (error) {
//       alert('An error occurred while submitting the form. Please try again later.');
//       console.error('Error:', error);
//     }
//   };

//   return (
//     <div className="App">
//       <Header />
//       <div className="mx-8 mt-8 mb-4">
//         <p className="ml-8 text-xl font-semibold">Respected Madam / Sir,</p>
//         <p className="mt-4 ml-8 mr-8 text-lg">
//           It gives us immense pleasure to present our prestigious business-to-business e-paper (weekly) and Magazine monthly titles –
//           {/* <Link to="/connect-media" className="text-orange-500 hover:underline">‘GARMENTLINE’</Link> from */}
//           <Link to="/connect-media" className="text-orange-500 hover:underline">‘Connect Media’</Link>.
//         </p>
//         <p className="mt-4 ml-8 mr-8 text-lg">
//           <span className="font-semibold text-gray-500">Connect Media is a weekly e-paper & monthly magazine</span> on the Business of GARMENT manufacturers, wholesale & <span className="font-semibold">retail</span> Industry (from Manufacturer to Retailer) covering industry updates & industry day-to-day events and up-and-coming trends. Subscribe Now,
//         </p>
//         <p className="mt-4 ml-8 mr-8 text-lg">
//           Pleased to have you as our valuable Subscriber of our <span className="font-semibold">Monthly magazine.</span> We present to you our best Subscription tariff offer.
//         </p>
//         <p className="mt-4 ml-8 mr-8 text-lg">
//           Do find attached a Subscription Form to be filled in, send across at the earliest.
//         </p>
//         <p className="mt-8 ml-8 text-2xl font-semibold">Subscription Form</p>
//          {popupVisible && (
//           <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
//             <div className="p-6 text-white bg-green-500 rounded-md shadow-lg">
//               <p className="text-xl font-bold">Form Successfully Submitted!</p>
//             </div>
//           </div>
//         )}
//         <form className="mt-8 ml-8 mr-8" onSubmit={handleSubmit}>
//           <div className="flex space-x-8">
//             <div className="w-1/2">
//               <label htmlFor="name" className="block mb-2 font-semibold">Contact Person</label>
//               <input type="text" id="name" value={formData.name} onChange={handleChange}
//                 className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 placeholder="Enter contact person's name"
//                 required
//               />
//             </div>
//             <div className="w-1/2">
//               <label htmlFor="companyName" className="block mb-2 font-semibold">Company Name</label>
//               <input
//                 type="text"
//                 id="companyName"
//                 value={formData.companyName}
//                 onChange={handleChange}
//                 className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 placeholder="Enter company name" required
//               />
//             </div>
//           </div>
//           <div className="mt-8">
//             <label htmlFor="address" className="block mb-2 font-semibold">Address</label>
//             <textarea
//               id="address"
//               value={formData.address}
//               onChange={handleChange}
//               className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//               placeholder="Enter your address" required
//               rows="2"
//             />
//           </div>
//           <div className="flex mt-8 space-x-8">
//             <div className="w-1/2">
//               <label htmlFor="email" className="block mb-2 font-semibold">Email ID</label>
//               <input
//                 type="email"
//                 id="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 placeholder="Enter your email" required
//               />
//             </div>
//             <div className="w-1/2">
//               <label htmlFor="mobileNumber" className="block mb-2 font-semibold">Mobile No.</label>
//               <input
//                 type="text"
//                 id="mobileNumber"
//                 value={formData.mobileNumber}
//                 onChange={handleChange}
//                 className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 placeholder="Enter your mobile number" required
//               />
//             </div>
//           </div>
//           <div className="flex mt-8 space-x-8">
//             <div className="w-1/2">
//               <label htmlFor="typeOfBusiness" className="block mb-2 font-semibold">Type of Business</label>
//               <select
//                 id="typeOfBusiness"
//                 value={formData.typeOfBusiness}
//                 onChange={handleChange}
//                 className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//               >
//                 <option value="" disabled>Choose from list</option>
//                 <option value="Garment Manufacturer">Garment Manufacturer</option>
//                 <option value="Wholesaler">Wholesaler</option>
//                 <option value="Agent/Distributor">Agent/Distributor</option>
//                 <option value="others">Other</option>
//               </select>
//             </div>
//           </div>
//           {/* <div className="mt-8">
//             <p className="mb-4 text-xl font-semibold">GARMENT LINE (optional)<br />
//               <span className="block text-sm text-gray-500">BUSINESS OF GARMENT FASHION</span>
//             </p>
//             <div className="flex space-x-8">
//               <div className="w-1/3">
//                 <label htmlFor="businessOfGarmentFashion.period" className="block mb-2 font-semibold">Period (Years) (optional)</label>
//                 <select
//                   id="businessOfGarmentFashion.period"
//                   value={formData.businessOfGarmentFashion.period}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select period</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="businessOfGarmentFashion.numberOfIssues" className="block mb-2 font-semibold">No. of Issues (optional)</label>
//                 <select
//                   id="businessOfGarmentFashion.numberOfIssues"
//                   value={formData.businessOfGarmentFashion.numberOfIssues}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select number</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                   <option value="4">4</option>
//                   <option value="5">5</option>
//                   <option value="6">6</option>
//                   <option value="7">7</option>
//                   <option value="8">8</option>
//                   <option value="9">9</option>
//                   <option value="10">10</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="businessOfGarmentFashion.totalCharge" className="block mb-2 font-semibold">Total Including Courier Charge (optional)</label>
//                 <select
//                   id="businessOfGarmentFashion.totalCharge"
//                   value={formData.businessOfGarmentFashion.totalCharge}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select total</option>
//                   <option value="3300">3300</option>
//                   <option value="5000">5000</option>
//                 </select>
//               </div>
//             </div>
//           </div> */}
//           {/* <div className="mt-8">
//             <p className="mb-4 text-xl font-semibold">INNER FASHION (optional)<br />
//               <span className="block text-sm text-gray-500">BUSINESS OF INNER FASHION</span>
//             </p>
//             <div className="flex space-x-8">
//               <div className="w-1/3">
//                 <label htmlFor="businessOfInnerFashion.period" className="block mb-2 font-semibold">Period (Years) (optional)</label>
//                 <select
//                   id="businessOfInnerFashion.period"
//                   value={formData.businessOfInnerFashion.period}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select period</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="businessOfInnerFashion.numberOfIssues" className="block mb-2 font-semibold">No. of Issues (optional)</label>
//                 <select
//                   id="businessOfInnerFashion.numberOfIssues"
//                   value={formData.businessOfInnerFashion.numberOfIssues}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select number</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                   <option value="4">4</option>
//                   <option value="5">5</option>
//                   <option value="6">6</option>
//                   <option value="7">7</option>
//                   <option value="8">8</option>
//                   <option value="9">9</option>
//                   <option value="10">10</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="businessOfInnerFashion.totalCharge" className="block mb-2 font-semibold">Total Including Courier Charge (optional)</label>
//                 <select
//                   id="businessOfInnerFashion.totalCharge"
//                   value={formData.businessOfInnerFashion.totalCharge}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select total</option>
//                   <option value="3300">2500</option>
//                   <option value="5000">3500</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//           <div className="mt-8">
//             <p className="mb-4 text-xl font-semibold">COMBINED GARMENT + INNER FASHION (optional)<br />
//             </p>
//             <div className="flex space-x-8">
//               <div className="w-1/3">
//                 <label htmlFor="garmentLineAndInnerFashion.period" className="block mb-2 font-semibold">Period (Years) (optional)</label>
//                 <select
//                   id="garmentLineAndInnerFashion.period"
//                   value={formData.garmentLineAndInnerFashion.period}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select period</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="garmentLineAndInnerFashion.numberOfIssues" className="block mb-2 font-semibold">No. of Issues (optional)</label>
//                 <select
//                   id="garmentLineAndInnerFashion.numberOfIssues"
//                   value={formData.garmentLineAndInnerFashion.numberOfIssues}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select number</option>
//                   <option value="1">1</option>
//                   <option value="2">2</option>
//                   <option value="3">3</option>
//                   <option value="4">4</option>
//                   <option value="5">5</option>
//                   <option value="6">6</option>
//                   <option value="7">7</option>
//                   <option value="8">8</option>
//                   <option value="9">9</option>
//                   <option value="10">10</option>
//                 </select>
//               </div>
//               <div className="w-1/3">
//                 <label htmlFor="garmentLineAndInnerFashion.totalCharge" className="block mb-2 font-semibold">Total Including Courier Charge (optional)</label>
//                 <select
//                   id="garmentLineAndInnerFashion.totalCharge"
//                   value={formData.garmentLineAndInnerFashion.totalCharge}
//                   onChange={handleChange}
//                   className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//                 >
//                   <option value="" disabled>Select total</option>
//                   <option value="3300">2500</option>
//                   <option value="5000">3500</option>
//                 </select>
//               </div>
//             </div>
//           </div> */}
//           {/* <div className="mt-8">
//             <label htmlFor="garmentLineAndInnerFashion.interested" className="block mb-2 font-semibold">YES, I am interested in Subscribing (optional)</label>
//             <select
//               id="garmentLineAndInnerFashion.interested"
//               value={formData.garmentLineAndInnerFashion.interested}
//               onChange={handleChange}
//               className="w-full p-3 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
//             >
//               <option value="" disabled>Select subscription type</option>
//               <option value="Garmentline">Connect Media</option>
//               {/* <option value="Inner fashion">InnerFashion</option> */}
//               {/* <option value="Garmentline & Inner fashion">Combined (GarmentLine & InnerFashion)</option> */}
//             {/* </select> */}
//           {/* </div> */} 
//           <div className="mt-8">
//             <button type="submit" className="px-6 py-3 font-semibold text-white transition bg-green-500 rounded-md hover:bg-green-600">
//               {isSubmitted ? 'Form Submitted' : 'Submit Form'}
//             </button>
//           </div>
//         </form>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default SubscribeNow;
