import React, { useState } from "react";
import { FaBackward, FaForward, FaTh, FaSearchPlus, FaSearchMinus, FaExpand, FaShareAlt, FaEllipsisH, FaFilePdf, FaLessThan } from "react-icons/fa";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiSoundOff } from "react-icons/gi";
import { IoBookOutline } from "react-icons/io5";
import ConnectMediaHeader from "../../ConnectMediaHeader/ConnectMediaHeader";
import Footer from "../../../GarmentLine/Footer/Footer";

const InnerFashionWeekly = () => {
  const [currentPage, setCurrentPage] = useState({ first: 1, second: 1 });
  const [maxPages] = useState({ first: 5, second: 5 });
  const [soundEnabled, setSoundEnabled] = useState({ first: true, second: true });
  const [showDropdown, setShowDropdown] = useState({ first: false, second: false });
  const [zoomLevel, setZoomLevel] = useState({ first: 1, second: 1 });
  const [isFullScreen, setIsFullScreen] = useState({ first: false, second: false });
  const [isDoublePageMode, setIsDoublePageMode] = useState(false);

  const handleForward = (id) => {
    if (currentPage[id] < maxPages[id]) {
      setCurrentPage((prev) => ({ ...prev, [id]: prev[id] + 1 }));
      playSound(id);
    }
  };

  const handleBackward = (id) => {
    if (currentPage[id] > 1) {
      setCurrentPage((prev) => ({ ...prev, [id]: prev[id] - 1 }));
      playSound(id);
    }
  };

  const playSound = (id) => {
    if (soundEnabled[id]) {
      const audio = new Audio("/page-flip-sound.mp3");
      audio.play();
    }
  };

  const toggleSound = (id) => {
    setSoundEnabled((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleDropdown = (id) => {
    setShowDropdown((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleZoomIn = (id) => {
    if (zoomLevel[id] < 2) {
      setZoomLevel((prev) => ({ ...prev, [id]: prev[id] + 0.1 }));
    }
  };

  const handleZoomOut = (id) => {
    if (zoomLevel[id] > 1) {
      setZoomLevel((prev) => ({ ...prev, [id]: prev[id] - 0.1 }));
    }
  };

  const handleExpand = (id) => {
    const newFullScreen = { ...isFullScreen, [id]: !isFullScreen[id] };
    setIsFullScreen(newFullScreen);
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = "/path-to-your-pdf-file.pdf";
    link.download = "magazine.pdf";
    link.click();
  };

  const toggleDoublePageMode = () => {
    setIsDoublePageMode((prev) => !prev);
  };

  const goToFirstPage = (id) => {
    setCurrentPage((prev) => ({ ...prev, [id]: 1 }));
  };

  const goToLastPage = (id) => {
    setCurrentPage((prev) => ({ ...prev, [id]: maxPages[id] }));
  };

  const renderControls = (id) => (
    <div className="w-full p-4 bg-gray-100 flex items-center justify-between">
      <button onClick={() => handleBackward(id)} disabled={currentPage[id] <= 1} className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaBackward />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Go Back</span>
      </button>
      <span className="mx-2">
        Page {currentPage[id]} of {maxPages[id]}
      </span>
      <button onClick={() => handleForward(id)} disabled={currentPage[id] >= maxPages[id]} className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaForward />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Go Forward</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={() => handleZoomIn(id)}>
        <FaSearchPlus />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Zoom In</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={() => handleZoomOut(id)}>
        <FaSearchMinus />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Zoom Out</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={() => handleExpand(id)}>
        <FaExpand />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Expand</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaShareAlt />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Share</span>
      </button>
      <div className="relative">
        <button className="p-2 rounded bg-gray-300 hover:bg-gray-400" onClick={() => toggleDropdown(id)}>
          <FaEllipsisH />
        </button>
        {showDropdown[id] && (
          <div className="absolute bottom-full right-0 bg-white shadow-md rounded-md p-4 w-64">
            <ul>
              <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center" onClick={handleDownloadPDF}>
                <FaFilePdf className="mr-2" />
                Download PDF
              </li>
              <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center" onClick={toggleDoublePageMode}>
                <IoBookOutline className="mr-1" />
                Double Page Mode
              </li>
              <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center" onClick={() => goToFirstPage(id)}>
                <MdOutlineKeyboardDoubleArrowLeft className="mr-1" />
                Go to First Page
              </li>
              <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center" onClick={() => goToLastPage(id)}>
                <MdOutlineKeyboardDoubleArrowRight className="mr-1" />
                Go to Last Page
              </li>
              <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center" onClick={() => toggleSound(id)}>
                <GiSoundOff />
                {soundEnabled[id] ? "Turn Off Sound" : "Turn On Sound"}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="App">
      <ConnectMediaHeader />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
        <h2 className="text-4xl mb-8 text-center">E-Paper Inner Fashion</h2>
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 mt-6 border border-gray-300">
        <h3 className="text-2xl text-center mb-6">October</h3>
        <div className="flex justify-between w-full max-w-6xl space-x-4">
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.first && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-first.pdf"width="100%"height="100%"title="First Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.first && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.first})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("first")}
              <p className="mt-4 text-lg font-medium">October I</p>
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.second && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-second.pdf"width="100%"height="100%"title="Second Magazine PDF" ></iframe>
                </div>
              )}
              {!isFullScreen.second && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.second})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("second")}
              <p className="mt-4 text-lg font-medium">October II</p>
            </div>
          </div>
          <div className="flex justify-between w-full max-w-6xl space-x-4 mt-6">
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.third && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-first.pdf" width="100%"height="100%"title="First Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.third && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.first})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("third")}
              <p className="mt-4 text-lg font-medium">October III</p>
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.fourth && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-second.pdf"width="100%"height="100%"title="Second Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.fourth && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.second})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("fourth")}
              <p className="mt-4 text-lg font-medium">October IV</p>
            </div>
          </div>
        </div>
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 mt-6 border border-gray-300 mt-8">
        <h3 className="text-2xl  text-center mb-6">September</h3>
        <div className="flex justify-between w-full max-w-6xl space-x-4">
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.first && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe
                    src="/path-to-your-pdf-file-first.pdf"
                    width="100%"
                    height="100%"
                    title="First Magazine PDF"
                  ></iframe>
                </div>
              )}
              {!isFullScreen.first && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.first})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("first")}
              <p className="mt-4 text-lg font-medium">September I</p>
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.second && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-second.pdf"width="100%"height="100%"title="Second Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.second && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.second})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("second")}
              <p className="mt-4 text-lg font-medium">Septmenber II</p>
            </div>
          </div>
          <div className="flex justify-between w-full max-w-6xl space-x-4 mt-6">
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.third && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-first.pdf"width="100%"height="100%"title="First Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.third && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.first})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("third")}
              <p className="mt-4 text-lg font-medium">Septmber III</p>
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
              {isFullScreen.fourth && (
                <div className="w-full h-[500px] bg-gray-200 flex justify-center items-center">
                  <iframe src="/path-to-your-pdf-file-second.pdf"width="100%"height="100%"title="Second Magazine PDF"></iframe>
                </div>
              )}
              {!isFullScreen.fourth && (
                <div className="relative w-full h-[500px] bg-gray-200 flex justify-center items-center" style={{ transform: `scale(${zoomLevel.second})` }}>
                  <p className="text-lg text-center">Flipbook Content</p>
                </div>
              )}
              {renderControls("fourth")}
              <p className="mt-4 text-lg font-medium">September IV</p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default InnerFashionWeekly;
