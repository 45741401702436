import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
 
const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
 
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
 
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
  return (
    <footer className="bg-white text-black py-8 px-6">
      {/* Row with links on the left and right */}
      <div className="flex justify-between items-center">
        {/* Left links with increased font size */}
        <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 ml-20">
          <Link to="/" className="text-pink-500 font-bold text-2xl hover:underline">
            connectmedia.online
          </Link>
          <div className="h-6 w-px bg-black sm:hidden"></div> 
          <Link to="https://connectmedia.online/garmentline" className="text-pink-500 font-bold text-2xl hover:underline">
            garmentline.live
          </Link>
          <div className="h-6 w-px bg-black sm:hidden"></div>
          <Link to="https://connectmedia.online/innerfashion" className="text-pink-500 font-bold text-2xl hover:underline">
            innerfashion.live
          </Link>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-x-4 text-sm text-black sm:mr-20">
          <span className="hidden sm:inline-block text-black">•</span>
          <Link to="/terms-condition" className="text-black hover:underline">
            Terms
          </Link>
          <span className="hidden sm:inline-block text-black">•</span>
          <Link to="/pricing" className="text-black hover:underline">
            Pricing
          </Link>
          <span className="hidden sm:inline-block text-black">•</span>
          <Link to="/privacy-policy" className="text-black hover:underline">
            Privacy
          </Link>
          <span className="hidden sm:inline-block text-black">•</span>
          <Link to="/contact" className="text-black hover:underline">
            Contact
          </Link>
          <span className="hidden sm:inline-block text-black">•</span>
          <Link to="/about-us" className="text-black hover:underline">
            About Us
          </Link>
        </div>
      </div>
      <p className="text-sm text-center mt-6">
        Copyright © 2025. All Rights Reserved by{' '}
        <Link to="/connect-media" className="text-orange-500 hover:underline">
          Connect Media
        </Link>.
      </p>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="bg-orange-500 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-orange-600 transition-colors fixed bottom-4 right-4"
          aria-label="Scroll to Top"
        >
          ↑
        </button>
      )}
    </footer>
  );
};
 
export default Footer;
 