import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronLeft,
  FaSearchPlus,
  FaSearchMinus,
  FaExpand,
  FaFilePdf,
} from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [garmentline, setgarment] = useState([]);
  const [innerfashion, setinnerfashion] = useState([]);
  const [filteredMagazines, setFilteredMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfURL, setPdfURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [fileToOpen, setFileToOpen] = useState(null);
  const [selectedMagazine, setSelectedMagazine] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const pdfContainerRef = useRef(null);
  const navigate = useNavigate();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  useEffect(() => {
    fetch(
      "https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazine"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          const sortedMagazines = data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          const filterMag = sortedMagazines.filter((magazine) => {
            const magazineDate = new Date(magazine.createdAt);
            return (
              magazineDate.getMonth() === currentMonth &&
              magazineDate.getFullYear() === currentYear
            );
          });
          const filtered = filterMag.map((magazine) => {
            const magazineDate = new Date(magazine.createdAt);
            return {
              ...magazine,
              monthName: magazineDate.toLocaleString("default", {
                month: "short",
              }), // Extracts "December"
            };
          });

          const garmentlineMagazines = filtered.filter(
            (magazine) => magazine.magazineType === "GARMENTLINE MAGAZINE"
          );
          const innerfashionMagazines = filtered.filter(
            (magazine) => magazine.magazineType === "INNERFASHION MAGAZINE"
          );
          setgarment(garmentlineMagazines);
          setinnerfashion(innerfashionMagazines);
        } else {
          setError("Unexpected data structure for magazines");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching magazines:", error);
        setError("Error fetching magazines");
        setLoading(false);
      });
  }, [currentMonth, currentYear]);

  const isLoggedIn = document.cookie.includes("accessToken");
  //  const isLoggedIn = Cookies.get("accessToken");
  const handleThumbnailClick = (fileUrl, title) => {
    setPdfURL(fileUrl);
    setSelectedMagazine(title);
    setCurrentPage(1);
  };

  const handleLoginRedirect = () => {
    setShowLoginPopup(false); // Close the popup
    navigate("/login"); // Redirect to the login page
  };

  const handleCancelLogin = () => {
    setShowLoginPopup(false); // Close the popup without redirecting
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 1.5)); // Max zoom in at 1.5x
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.05, 0.5)); // Min zoom out at 0.5x
  };

  const handleExpand = () => {
    if (pdfContainerRef.current) {
      const pdfContainer = pdfContainerRef.current;
      if (!document.fullscreenElement) {
        if (pdfContainer.requestFullscreen) {
          pdfContainer.requestFullscreen();
        } else if (pdfContainer.mozRequestFullScreen) {
          // Firefox
          pdfContainer.mozRequestFullScreen();
        } else if (pdfContainer.webkitRequestFullscreen) {
          // Chrome, Safari, and Opera
          pdfContainer.webkitRequestFullscreen();
        } else if (pdfContainer.msRequestFullscreen) {
          // IE/Edge
          pdfContainer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }
    }
  };

  const handleDownloadPDF = () => {
    if(isLoggedIn){
      const link = document.createElement("a");
      link.href = pdfURL;
      link.download = "magazine.pdf";
      link.click();
    }
    else{
      setShowLoginPopup(true);
    }
  };

  const handleBackToList = () => {
    setPdfURL(null);
  };

  if (loading) {
    return (
      <div>
        <Header />
        <div className="flex items-center justify-center min-h-screen">
          <h1>Loading...</h1>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Header />
        <div className="flex items-center justify-center min-h-screen">
          <h1>{error}</h1>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <div className="flex flex-col items-center min-h-screen p-8 bg-gray-100">
        {/* Show Login Popup */}
        {showLoginPopup && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-1/3 p-8 text-center bg-white rounded-lg shadow-lg">
              <h2 className="text-xl font-semib~old mb-4">Login Required</h2>
              <p className="mb-4">
                You need to be logged in to access the magazine. Please log in
                to continue.
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={handleLoginRedirect}
                  className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600"
                >
                  Go to Login
                </button>
                <button
                  onClick={handleCancelLogin}
                  className="px-4 py-2 text-white bg-gray-500 rounded-full hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {pdfURL ? (
          <div
            className="flex flex-col items-center justify-center w-full max-w-4xl"
            ref={pdfContainerRef}
          >
            <button
              onClick={handleBackToList}
              className="flex items-center mb-4 text-blue-500"
            >
              <FaChevronLeft className="mr-2" />
              Back to Magazines
            </button>
            <Document
              file={pdfURL}
              onLoadSuccess={handleDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Failed to load PDF:", error)
              }
              className="p-4 mb-4 border rounded-lg shadow-lg"
            >
              <Page pageNumber={currentPage} width={550 * zoomLevel} />
            </Document>
            <div className="flex justify-between w-full max-w-4xl mt-4">
              <button
                onClick={goToPrevPage}
                className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage <= 1}
              >
                <FaArrowLeft color="white" />
              </button>
              <div className="flex items-center justify-center space-x-2">
                <span className="text-lg text-center">
                  {currentPage} of {numPages}
                </span>
              </div>
              <button
                onClick={goToNextPage}
                className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage >= numPages}
              >
                <FaArrowRight color="white" />
              </button>
            </div>
            <div className="flex items-center justify-center mt-4 space-x-4">
              <button
                onClick={handleZoomIn}
                className="p-2 bg-gray-300 rounded-full hover:bg-gray-400"
              >
                <FaSearchPlus />
              </button>
              <button
                onClick={handleZoomOut}
                className="p-2 bg-gray-300 rounded-full hover:bg-gray-400"
              >
                <FaSearchMinus />
              </button>
              <button
                onClick={handleExpand}
                className="p-2 bg-gray-300 rounded-full hover:bg-gray-400"
              >
                <FaExpand />
              </button>
              <button
                onClick={handleDownloadPDF}
                className="p-2 bg-gray-300 rounded-full hover:bg-gray-400"
              >
                <FaFilePdf />
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-6xl p-6 mt-6 bg-white border border-gray-300 rounded-lg">
            {garmentline.length > 0 && (
              <>
                <h2 className="mb-4 text-4xl font-extrabold text-center text-pink-500">
                  GARMENTLINE
                </h2>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
                  {garmentline.slice(0, 4).map((magazine, index) => (
                    <div
                      key={magazine._id}
                      onClick={() => handleThumbnailClick(magazine.uploadYourFile,magazine.title)}
                      className="h-[70vh] flex flex-col items-center w-full max-w-xs mb-16 transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer  hover:scale-105"
                    >
                      
                        <img
                          src={magazine.chooseYourThumbnail}
                          alt={`Garmentline ${index}`}
                          className="w-auto rounded-lg h-96"
                        />
                      
                      <div className="h-[10%]">
                      <p className="mt-4 mb-2 text-lg font-medium">
                        {magazine.userName} {magazine.monthName}
                      </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {innerfashion.length > 0 && (
              <>
                <h2 className="mt-8 mb-4 text-4xl font-extrabold text-center text-pink-500">
                  INNERFASHION
                </h2>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
                  {innerfashion.slice(0, 4).map((magazine, index) => (
                    <div
                      key={magazine._id}
                      onClick={() => handleThumbnailClick(magazine.uploadYourFile,magazine.title)}
                      className="h-[70vh] flex flex-col items-center w-full max-w-xs mb-16 transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer  hover:scale-105"
                    >
                      <img
                        src={magazine.chooseYourThumbnail}
                        alt={`Innerfashion ${index}`}
                        className="w-auto rounded-lg h-96"
                      />
                      <div className="h-[10%]">
                      <p className="mt-4 mb-2 text-lg font-medium">
                        {magazine.userName} {magazine.monthName}
                      </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {garmentline.length === 0 && innerfashion.length === 0 && (
              <p>No magazines available for this month.</p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
