import React, { useState, useEffect, useRef } from 'react'; 
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Document, Page, pdfjs } from "react-pdf";
import { useTransition, animated } from 'react-spring';
import { FaArrowRight, FaArrowLeft, FaChevronLeft, FaSearchPlus, FaSearchMinus, FaExpand, FaFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Innerfashion = () => {
  const [magazines, setMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfURL, setPdfURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMagazine, setSelectedMagazine] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const pdfContainerRef = useRef(null); 
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


  const isLoggedIn = document.cookie.includes('accessToken');

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
}, []);

useEffect(() => {
    const fetchMagazines = async () => {
        try {
            const response = await fetch('https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazine');
            const data = await response.json();
            
            if (data && Array.isArray(data.data)) {
                const sortedMagazines = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // const currentMonth = new Date().getMonth();
                // const filteredMagazines = sortedMagazines.filter((magazine) => {
                //     const magazineDate = new Date(magazine.createdAt);
                //     return magazineDate.getMonth() === currentMonth;
                // });
                const filtered = sortedMagazines.map((magazine) => {
                    const magazineDate = new Date(magazine.createdAt);
                    return {
                      ...magazine,
                      monthName: magazineDate.toLocaleString('default', { month: 'short' }), // Extracts "December"
                    };
                  });
                const innerfashionMagazines = filtered.filter(magazine => magazine.magazineType === "INNERFASHION MAGAZINE");

                setMagazines(innerfashionMagazines);
            } else {
                setError("Unexpected data structure for magazines");
            }
        } catch (error) {
            setError("Error fetching magazines");
        } finally {
            setLoading(false);
        }
    };

    fetchMagazines();
}, []);
  useEffect(() => {
    if (showLoginPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showLoginPopup]);

  const transitions = useTransition(currentPage, {
      key: currentPage,
      from: {
        opacity: 0,
        transform: "rotateY(-90deg) translateX(100%)", // Start from the right (out of view)
      },
      enter: {
        opacity: 1,
        transform: "rotateY(0deg) translateX(0)", // End with page in normal position at the center
      },
      leave: {
        scale: 0,
        transform: "rotateY(90deg) translateX(-100%)",
      // Move to the left when leaving
      }, // Leave by rotating back to the other side
      reverse: currentPage % 2 === 0,  // When leaving, become smaller and fade out
    });

  const handleThumbnailClick = (fileUrl, title) => {

      setPdfURL(fileUrl);
      setSelectedMagazine(title);
      setCurrentPage(1);
  };

  const handleLoginRedirect = () => {setShowLoginPopup(false);navigate('/login');};
  const handleCancelLogin = () => {setShowLoginPopup(false);};
  const handleDocumentLoadSuccess = ({ numPages }) => {setNumPages(numPages);};

  const goToNextPage = () => {if (currentPage < numPages) {setCurrentPage((prevPage) => prevPage + 1);}};
  const goToPrevPage = () => {if (currentPage > 1) {setCurrentPage((prevPage) => prevPage - 1);}};
 const handleBackToList = () => {setPdfURL(null);setSelectedMagazine(null);};
  const handleZoomIn = () => {setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 1.5));};
  const handleZoomOut = () => {setZoomLevel((prevZoom) => Math.max(prevZoom - 0.05, 0.5));};

  const handleExpand = () => {
    if (pdfContainerRef.current) {
      const pdfContainer = pdfContainerRef.current;
      if (!document.fullscreenElement) {
        if (pdfContainer.requestFullscreen) {
          pdfContainer.requestFullscreen();
        } else if (pdfContainer.mozRequestFullScreen) {
          pdfContainer.mozRequestFullScreen();
        } else if (pdfContainer.webkitRequestFullscreen) {
          pdfContainer.webkitRequestFullscreen();
        } else if (pdfContainer.msRequestFullscreen) {
          pdfContainer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };
  const handleDownloadPDF = () => {
    if(isLoggedIn){
      const link = document.createElement('a');
      link.href = pdfURL;
      link.download = 'magazine.pdf';
      link.click();
    }
    else{
      setShowLoginPopup(true);
    }
  };
  if (loading) {
    return (
      <div>
        <Header />
        <div className="flex items-center justify-center min-h-screen">
          <h1 className="text-2xl">Loading...</h1>
        </div>
        <Footer />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <Header />
        <div className="flex items-center justify-center min-h-screen">
          <h1 className="text-2xl">{error}</h1>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div className="App">
      <Header />
      <div className="flex flex-col items-center min-h-screen p-8 bg-gray-100">
        <h1 className="mb-8 text-4xl font-extrabold text-center text-pink-500">INNERFASHION</h1>
        {showLoginPopup && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-1/3 p-8 text-center bg-white rounded-lg shadow-lg">
              <h2 className="mb-4 text-xl font-semibold">Login Required</h2>
              <p className="mb-4">You need to be logged in to access the magazine. Please log in to continue.</p>
              <div className="flex justify-center gap-4">
                <button onClick={handleLoginRedirect}className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600">
                  Go to Login
                </button>
                <button onClick={handleCancelLogin}className="px-4 py-2 text-white bg-gray-500 rounded-full hover:bg-gray-600">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {pdfURL ? (
          <div className="flex flex-col items-center justify-center w-full max-w-4xl" ref={pdfContainerRef}>
            <button onClick={handleBackToList}className="flex items-center mb-4 text-blue-500">
              <FaChevronLeft className="mr-2" />
              Back to Magazines
            </button>
            {/* <Document file={pdfURL} onLoadSuccess={handleDocumentLoadSuccess} className="p-4 mb-4 border rounded-lg shadow-lg">
              {transitions((style, page) => (
                <animated.div style={style}>
                  <ReactPdfPage pageNumber={page} width={550*zoomLevel}/>
                </animated.div>
              ))}
            </Document> */}
            <Document
              file={pdfURL}
              onLoadSuccess={handleDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Failed to load PDF:", error)
              }
              className="p-4 mb-4 border rounded-lg shadow-lg"
            >
              <Page pageNumber={currentPage} width={550 * zoomLevel} />
            </Document>
            <div className="flex justify-between w-full max-w-4xl mt-4">
              <button onClick={goToPrevPage}className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 disabled:opacity-50"disabled={currentPage <= 1}>
                <FaArrowLeft color="white" />
              </button>
              <div className="flex items-center justify-center space-x-2">
                <span className="text-lg text-center">{currentPage} of {numPages}</span>
              </div>
              <button onClick={goToNextPage}className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 disabled:opacity-50" disabled={currentPage >= numPages}>
                <FaArrowRight color="white" />
              </button>
            </div>
            <div className="flex items-center justify-center mt-4 space-x-4">
              <button onClick={handleZoomIn} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400">
                <FaSearchPlus />
              </button>
              <button onClick={handleZoomOut} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400">
                <FaSearchMinus />
              </button>
              <button onClick={handleExpand} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400">
                <FaExpand />
              </button>
              <button onClick={handleDownloadPDF} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400">
                <FaFilePdf />
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-6xl p-6 mt-6 bg-white border border-gray-300 rounded-lg shadow-lg">
            {magazines.length > 0 ? (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
                {magazines.map((magazine, index) => (
                  <div key={index} onClick={() => handleThumbnailClick(magazine.uploadYourFile, magazine.title)}
                    className="flex flex-col items-center w-full max-w-xs mb-16 transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer hover:scale-105"
                  >
                    <img src={magazine.chooseYourThumbnail}alt={`Magazine ${index}`} className="w-auto rounded-lg h-96"/>
                    <p className="mt-4 mb-2 text-lg font-medium">{magazine.userName} {magazine.monthName}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-lg text-center">No magazines available.</p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default Innerfashion;
