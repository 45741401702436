import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="App">
      <Header />
      <div className="flex items-start space-x-8 mt-8 ml-8 mr-8">
        <div className="w-1/2 text-gray-800">
          <h2 className="text-2xl font-bold mb-4">Address</h2>
          <p className="mb-4">
            <strong>CONNECT MEDIA</strong>
          </p>
          <p className="mb-4 font-semibold text-gray-500">
            D-30A, Ground Floor, Shakarpur,East Delhi, Delhi-110092
          </p>

          <h3 className="text-xl font-semibold mb-2">Office Phone</h3>
          <p className="mb-4 font-semibold text-gray-500">
            Tel: 011- 45542008, 9999703218
          </p>

          <h3 className="text-xl font-semibold mb-2">Email</h3>
          <p className="mb-4 font-semibold text-gray-500">
            connectmedia.adv@gmail.com
          </p>

          <h3 className="text-xl font-semibold mb-2">Website</h3>
          <p>
            <Link to="http://www.connectmedia.online" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
              www.connectmedia.online
            </Link>
            <br/>
            <Link to="http://www.garmentline.live" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
              www.garmentline.live
            </Link>
            <br/>
            <Link to="http://www.innerfashion.live" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
              www.innerfashion.live
            </Link>
          </p>
        </div>
        <div className="w-1/2">
          <img 
            src="/assets/image3.png" 
            alt="Garment Industry Uncertainty" 
            className="w-full h-auto"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
