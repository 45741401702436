import React, { useState, useEffect } from 'react';
import Footer from '../../GarmentLine/Footer/Footer';
import ConnectMediaHeader from '../ConnectMediaHeader/ConnectMediaHeader';
import { Document, Page } from 'react-pdf';
import { FaArrowRight, FaArrowLeft, FaSearchPlus, FaSearchMinus, FaExpand, FaFilePdf, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ConnectMediaHome = () => {
  const [garmentlineMagazines, setGarmentlineMagazines] = useState([]);
  const [innerFashionMagazines, setInnerFashionMagazines] = useState([]);
  const [medicalOnlineMagazines, setMedicalOnlineMagazines] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [fileToOpen, setFileToOpen] = useState(null); // Store file URL to open after login
   const navigate = useNavigate();
  
  const filterLatestMonthMagazines = (magazines) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return magazines.filter((magazine) => {
      const magazineDate = new Date(magazine.createdAt);
      return (
        magazineDate.getFullYear() === currentYear && magazineDate.getMonth() === currentMonth
      );
    });
  };

  // Fetch data from the given APIs
  const fetchMagazines = async () => {
    try {
      const garmentlineResponse = await fetch('https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazineGarmentLine');
      const innerFashionResponse = await fetch('https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazineInnerFashion');
      const medicalOnlineResponse = await fetch('https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazineMedicalOnline');

      const garmentlineData = await garmentlineResponse.json();
      const innerFashionData = await innerFashionResponse.json();
      const medicalOnlineData = await medicalOnlineResponse.json();

      // Filter magazines to show only the ones from the current month
      setGarmentlineMagazines(filterLatestMonthMagazines(garmentlineData.data));
      setInnerFashionMagazines(filterLatestMonthMagazines(innerFashionData.data));
      setMedicalOnlineMagazines(filterLatestMonthMagazines(medicalOnlineData.data));
    } catch (error) {
      console.error('Error fetching magazines:', error);
    }
  };

  useEffect(() => {
    fetchMagazines();
  }, []);

  const isLoggedIn = document.cookie.includes('accessToken'); // Check if the user is logged in based on the cookie

  const handleThumbnailClick = (fileUrl) => {
    if (isLoggedIn) {
      setSelectedMagazine(fileUrl);
      setCurrentPage(1); // Reset page to 1 when a new PDF is selected
    } else {
      setShowLoginPopup(true);
      setFileToOpen(fileUrl); // Store the file URL to open after login
    }
  };

  const handleLoginRedirect = () => {
    setShowLoginPopup(false);
    navigate('/login'); // Redirect to the login page
  };

  const handleCancelLogin = () => {
    setShowLoginPopup(false); // Close the popup without redirecting
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Set the number of pages in the PDF
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 1.5));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.05, 0.5));
  };

  const handleExpand = () => {
    window.open(selectedMagazine, '_blank'); // Open PDF in new tab
  };

  const handleDownloadPDF = () => {
    const link = document.createElement('a');
    link.href = selectedMagazine;
    link.download = selectedMagazine.split('/').pop();
    link.click();
  };

  const renderMagazines = (magazines, sectionName) => (
    <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 mt-6 border border-gray-300">
      <h3 className="text-2xl text-center mb-6">{sectionName}</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {magazines.map((magazine, index) => (
          <div
            key={index}
            onClick={() => handleThumbnailClick(magazine.uploadYourFile)}
            className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition cursor-pointer"
          >
            <img
              src={magazine.uploadYourThumbnail}
              alt={magazine.mediaName}
              className="w-full h-100 object-cover rounded-lg mb-4"
            />
            <p className="mt-4 text-lg font-medium">{magazine.mediaName}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="App">
      <ConnectMediaHeader />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-8">
        <h1 className="text-6xl font-bold mb-8 text-center text-gray-800">Monthly Magazines</h1>

        {/* Show Login Popup */}
        {showLoginPopup && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/3 text-center">
              <h2 className="text-xl font-semibold mb-4">Login Required</h2>
              <p className="mb-4">You need to be logged in to access the magazine. Please log in to continue.</p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={handleLoginRedirect}
                  className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600"
                >
                  Go to Login
                </button>
                <button
                  onClick={handleCancelLogin}
                  className="bg-gray-500 text-white py-2 px-4 rounded-full hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedMagazine ? (
          <div className="flex flex-col justify-center items-center w-full max-w-4xl">
            <button
              onClick={() => setSelectedMagazine(null)}
              className="mb-4 text-blue-500 flex items-center"
            >
              <FaChevronLeft className="mr-2" />
              Back to Magazines
            </button>

            {/* PDF Viewer */}
            <Document file={selectedMagazine} onLoadSuccess={handleDocumentLoadSuccess} className="border rounded-lg shadow-lg p-4 mb-4">
              <Page pageNumber={currentPage} width={500 * zoomLevel} />
            </Document>

            {/* Navigation Controls */}
            <div className="flex justify-between w-full max-w-4xl mt-4">
              <button
                onClick={goToPrevPage}
                className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage <= 1}
              >
                <FaArrowLeft color="white" />
              </button>

              <div className="flex items-center justify-center space-x-2">
                <span className="text-lg text-center">{currentPage} of {numPages}</span>
              </div>

              <button
                onClick={goToNextPage}
                className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage >= numPages}
              >
                <FaArrowRight color="white" />
              </button>
            </div>

            {/* Zoom and other buttons */}
            <div className="flex justify-center items-center mt-4 space-x-4">
              <button onClick={handleZoomIn} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaSearchPlus />
              </button>
              <button onClick={handleZoomOut} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaSearchMinus />
              </button>
              <button onClick={handleExpand} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaExpand />
              </button>
              <button onClick={handleDownloadPDF} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaFilePdf />
              </button>
            </div>
          </div>
        ) : (
          <>
            {renderMagazines(garmentlineMagazines, 'GARMENTLINE')}
            {renderMagazines(innerFashionMagazines, 'INNER FASHION')}
            {renderMagazines(medicalOnlineMagazines, 'MEDICAL ONLINE')}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ConnectMediaHome;
