import { Document, Page, pdfjs } from "react-pdf";
import React, { useState, useEffect } from "react";
import { FaBackward, FaForward, FaSearchPlus, FaSearchMinus, FaExpand, FaShareAlt, FaFilePdf } from "react-icons/fa";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiSoundOff } from "react-icons/gi";
import { IoBookOutline } from "react-icons/io5";
import ConnectMediaHeader from "../../ConnectMediaHeader/ConnectMediaHeader";
import Footer from "../../../GarmentLine/Footer/Footer";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const WeeklyMagazineEpaper = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(5);
  const [magazines, setMagazines] = useState([]);
  const [currentMagazine, setCurrentMagazine] = useState(null);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazineGarmentLine");
        const data = await response.json();
        const weeklyMagazines = data.data.filter((mag) => mag.type === "Weekly");
        setMagazines(weeklyMagazines);
        if (weeklyMagazines.length > 0) {
          setCurrentMagazine(weeklyMagazines[0]);
          setMaxPages(weeklyMagazines[0].pages);  // Assuming the API returns the number of pages
        }
      } catch (error) {
        console.error("Error fetching magazines:", error);
      }
    };

    fetchMagazines();
  }, []);

  const handleForward = () => {
    if (currentPage < maxPages) {
      setCurrentPage((prev) => prev + 1);
      playSound();
    }
  };

  const handleBackward = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      playSound();
    }
  };

  const playSound = () => {
    if (soundEnabled) {
      const audio = new Audio("/page-flip-sound.mp3");
      audio.play();
    }
  };

  const toggleSound = () => {
    setSoundEnabled((prev) => !prev);
  };

  const handleZoomIn = () => {
    if (zoomLevel < 2) {
      setZoomLevel((prev) => prev + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel((prev) => prev - 0.1);
    }
  };

  const handleExpand = () => {
    setIsFullScreen((prev) => !prev);
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = currentMagazine?.pdfUrl;  // Assuming each magazine has a pdfUrl
    link.download = `${currentMagazine?.mediaName}.pdf`;
    link.click();
  };

  const handleMagazineChange = (id) => {
    const magazine = magazines.find((mag) => mag.id === id);
    if (magazine) {
      setCurrentMagazine(magazine);
      setCurrentPage(1);  // Reset to first page
      setMaxPages(magazine.pages);  // Set max pages from API
    }
  };

  const renderControls = () => (
    <div className="w-full p-4 bg-gray-100 flex items-center justify-between">
      <button onClick={handleBackward} disabled={currentPage <= 1} className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaBackward />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Go Back</span>
      </button>
      <span className="mx-2">
        Page {currentPage} of {maxPages}
      </span>
      <button onClick={handleForward} disabled={currentPage >= maxPages} className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaForward />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Go Forward</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={handleZoomIn}>
        <FaSearchPlus />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Zoom In</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={handleZoomOut}>
        <FaSearchMinus />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Zoom Out</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group" onClick={handleExpand}>
        <FaExpand />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Expand</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400 relative group">
        <FaShareAlt />
        <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">Share</span>
      </button>
      <button className="p-2 rounded bg-gray-300 hover:bg-gray-400" onClick={handleDownloadPDF}>
        <FaFilePdf />
      
      </button>
    </div>
  );

  return (
    <div className="App">
      <ConnectMediaHeader />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
        <h1 className="text-6xl mb-8 text-center">Weekly Magazines</h1>
        <div className="flex justify-center space-x-6 w-full max-w-5xl mb-8">
          {/* You can add thumbnails or other UI elements if necessary */}
        </div>
        <div className="flex justify-center space-x-6 w-full max-w-5xl mb-8">
          <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg w-1/2">
            {/* PDF Viewer */}
            <Document file={currentMagazine?.uploadYourFile} onLoadSuccess={({ numPages }) => setMaxPages(numPages)}>
              <Page pageNumber={currentPage} width={440 * zoomLevel} />
            </Document>
            {renderControls()}
            <p className="mt-4 text-lg font-medium">{currentMagazine?.mediaName}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WeeklyMagazineEpaper;
