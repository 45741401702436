import React, { useState, useEffect } from "react";
import Header from "../GarmentLine/Header/Header";
import Footer from "../GarmentLine/Footer/Footer";

const formatDateRange = (startDate, endDate) => {
  if (!startDate) return ""; // Return empty if no start date

  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;
  
  if (end) {
    return `${start.getDate()}-${end.getDate()} ${start.toLocaleString("en-GB", { month: "long" })} ${start.getFullYear()}`;
  }
  
  return start.toLocaleDateString("en-GB", { day: "2-digit", month: "long", year: "numeric" });
};

const Event = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetch("https://garment-line-backend-0pol.onrender.com/apis/user/getAllEvent")
      .then((response) => response.json())
      .then((data) => setEvents(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="App">
      <Header />

      <div className="min-h-screen p-4 overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-500">
          <thead>
            <tr className="bg-black">
            <th className="px-4 py-2 text-white border">Date</th>
              <th className="px-4 py-2 text-white border">Event Name</th>
             
              <th className="px-4 py-2 text-white border">Venue</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => {
              const formattedDate = formatDateRange(event.startDate, event.endDate);

              return (
                <tr key={event._id} className={index % 2 === 0 ? "bg-pink-200" : "bg-white"}>
                  <td className="px-4 py-2 text-center border">{formattedDate}</td>
                  <td className="px-4 py-2 text-center border">{event.event}</td>
                  <td className="px-4 py-2 text-center border">{event.venue}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  );
};

export default Event;
