import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { FaSpinner } from 'react-icons/fa';

const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); 
  const [resetPhone, setResetPhone] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetLoading, setResetLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false); 
  const [userId, setuserId] = useState(null); 
  const navigate = useNavigate();

  const getAuthToken = () => {
    return Cookies.get("accessToken");
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    const existingToken = getAuthToken();
    if (existingToken) {
      console.log("Token already found in cookies. Redirecting to home.");
      navigate("/home");
      return;
    }
    
    console.log("Phone:", phone);
    console.log("Pass:", password);
    
    try {
      const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/userAuth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "phoneNumber": `+91${phone}`, "password": password }),
      });
      const data = await response.json();
      if (response.ok && data.data && data.data.accessToken) {
        const token = data.data.accessToken;
        Cookies.remove("accessToken");
        localStorage.removeItem("authToken");
        Cookies.set("accessToken", token, { expires: 1 });
        console.log("New token saved to cookies:", token);

        navigate("/home");
      } else {
        setError(data.message || "Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Login Error:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setResetError('');
    if (!resetPhone) {
      setResetError("Please enter your phone no.");
      return;
    }
    setResetLoading(true);
    try {
      const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/userAuth/sendOtp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone: resetPhone }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        setStep(2);
        alert("OTP has been sent to your phone number.");
      } else {
        setResetError(data.message || "Failed to send OTP.");
      }
    } catch (error) {
      setResetError("An error occurred. Please try again later.");
    } finally {
      setResetLoading(false);
    }
  };
  const handleOtpVerification = async (e) => {
    e.preventDefault();
    if (!otp) {
      setResetError("Please enter the OTP.");
      return;
    }
    setResetLoading(true);
    try {
      const otpData = { otp, phone: resetPhone };
      const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/userAuth/verifyOtp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(otpData),
      });
      const data = await response.json();
      console.log("data",data);
      if (response.ok && data.success) {
        setuserId(data.data.userId);
        localStorage.setItem("userId", data.data.userId);
        console.log("OTP Verified successfully. Admin ID:", data.data.userId);
        alert("OTP Verified successfully");
        setOtpVerification(true);
        setStep(3);
      } else {
        setResetError(data.message || "Invalid OTP.");
      }
    } catch (error) {
      setResetError("An error occurred while verifying OTP. Please try again later.");
    } finally {
      setResetLoading(false);
    }
  };
 const handleResetPassword = async (e) => {
  e.preventDefault();
  if (!newPassword || !confirmPassword) {
    setResetError("Please fill all fields.");
    return;
  }
  if (newPassword !== confirmPassword) {
    setResetError("Passwords do not match.");
    return;
  }
  setResetLoading(true);
  try {
    const storeduserId = userId || localStorage.getItem("userId");
    if (!storeduserId) {
      setResetError("Admin ID is missing. Please try the OTP verification again.");
      return;
    }
    const resetData = { userId: storeduserId,newPassword,confirmPassword};
    const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/userAuth/reset", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(resetData),
    });
    const resetDataResponse = await response.json();
    if (response.ok) {
      alert("Password has been reset successfully.");
      setShowForgotPassword(false);
      navigate("/login");
    } else {
      setResetError(resetDataResponse.message || "Failed to reset password.");
    }
  } catch (error) {
    setResetError("An error occurred. Please try again later.");
  } finally {
    setResetLoading(false);
  }
};
  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/phoneTemplate.jpg')" }}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-2xl font-semibold text-left mb-2">Login</h2>
        <p className="text-left text-gray-600 mb-6">Please enter your login credentials</p>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">Phone Number</label>
            <input type="phone"id="phone"value={phone}onChange={(e) => setPhone(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required placeholder="Enter your phone number"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
            <input type="password"id="password"placeholder='Enter Your Password'value={password} onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <div className="text-right mt-2">
              <button type="button"className="text-red-500 hover:underline text-sm"onClick={() => setShowForgotPassword(true)}>
                Forgot Password?
              </button>
            </div>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-white w-5 h-5 mr-2" />
                Logging in...
              </div>
            ) : (
              "Login"
            )}
          </button>
          <div className="text-left mt-2 text-sm">
            Don't have an account?{' '}
            <Link to="/sign-up" className="text-blue-500 hover:underline">Please Sign Up</Link>
          </div>
        </form>
      </div>
      {showForgotPassword && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Reset Your Password</h3>
            {step === 1 && (
              <form onSubmit={handleForgetPassword}>
                <div className="mb-4">
                  <label htmlFor="resetPhone" className="block text-gray-700 text-sm font-bold mb-2">
                    Enter your phone number
                  </label>
                  <input type="phone"id="resetPhone"value={resetPhone}onChange={(e) => setResetPhone(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                {resetError && <div className="text-red-500 text-sm mb-4">{resetError}</div>}
                <button type="submit"className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"disabled={resetLoading}>
                  {resetLoading ? "Sending OTP..." : "Send OTP"}
                </button>
              </form>
            )}
            {step === 2 && !otpVerification && (
              <div className="mb-4">
                <label htmlFor="otp" className="block text-gray-700 text-sm font-bold mb-2">
                  Enter OTP
                </label>
                <input type="text"id="otp"value={otp} onChange={(e) => setOtp(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                {resetError && <div className="text-red-500 text-sm mt-2">{resetError}</div>}
                <button type="button"onClick={handleOtpVerification} className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 mt-4"
                  disabled={resetLoading}
                > 
                  {resetLoading ? "Verifying OTP..." : "Verify OTP"}
                </button>
              </div>
            )}
            {step === 3 && otpVerification && (
              <form onSubmit={handleResetPassword}>
                <div className="mb-4">
                  <label htmlFor="newPassword" className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
                  <input type="password"id="newPassword"value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
                  <input type="password"id="confirmPassword"value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                {resetError && <div className="text-red-500 text-sm mb-4">{resetError}</div>}
                <button type="submit"className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"disabled={resetLoading}>
                  {resetLoading ? "Resetting..." : "Reset Password"}
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Login; 