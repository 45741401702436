import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FaArrowLeft, FaArrowRight, FaChevronLeft, FaSearchPlus, FaSearchMinus, FaExpand, FaFilePdf } from 'react-icons/fa';
import ConnectMediaHeader from '../../ConnectMediaHeader/ConnectMediaHeader';
import { useNavigate } from 'react-router-dom';

const MonthlyMagazine = () => {
  const [magazines, setMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfURL, setPdfURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [fileToOpen, setFileToOpen] = useState(null);
  const pdfContainerRef = useRef(null);
  const navigate = useNavigate();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  useEffect(() => {
    fetch('https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazine')
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          const sortedMagazines = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setMagazines(sortedMagazines);
        } else {
          setError('Unexpected data structure for magazines');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching magazines:', error);
        setError('Error fetching magazines');
        setLoading(false);
      });
  }, []);

  const isLoggedIn = document.cookie.includes('accessToken');

  const handleThumbnailClick = (fileUrl) => {
    if (isLoggedIn) {
      setPdfURL(fileUrl);
      setCurrentPage(1); // Reset to the first page
    } else {
      setShowLoginPopup(true);
      setFileToOpen(fileUrl); // Store file URL to open after login
    }
  };

  const handleLoginRedirect = () => {
    setShowLoginPopup(false); // Close the popup
    navigate('/login'); // Redirect to the login page
  };

  const handleCancelLogin = () => {
    setShowLoginPopup(false); // Close the popup without redirecting
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 1.5)); // Max zoom in at 1.5x
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.05, 0.5)); // Min zoom out at 0.5x
  };

  const handleExpand = () => {
    if (pdfContainerRef.current) {
      const pdfContainer = pdfContainerRef.current;
      if (!document.fullscreenElement) {
        if (pdfContainer.requestFullscreen) {
          pdfContainer.requestFullscreen();
        } else if (pdfContainer.mozRequestFullScreen) { // Firefox
          pdfContainer.mozRequestFullScreen();
        } else if (pdfContainer.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          pdfContainer.webkitRequestFullscreen();
        } else if (pdfContainer.msRequestFullscreen) { // IE/Edge
          pdfContainer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }
    }
  };

  const handleDownloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfURL;
    link.download = 'magazine.pdf';
    link.click();
  };

  const handleBackToList = () => {
    setPdfURL(null);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <h1 className="text-2xl">Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <h1 className="text-2xl">{error}</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <ConnectMediaHeader />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-8">
        <h1 className="text-6xl font-bold mb-8 text-center text-gray-800">Monthly Magazines</h1>

        {/* Show Login Popup */}
        {showLoginPopup && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 overflow-auto">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/3 text-center">
              <h2 className="text-xl font-semibold mb-4">Login Required</h2>
              <p className="mb-4">You need to be logged in to access the magazine. Please log in to continue.</p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={handleLoginRedirect}
                  className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600"
                >
                  Go to Login
                </button>
                <button
                  onClick={handleCancelLogin}
                  className="bg-gray-500 text-white py-2 px-4 rounded-full hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {pdfURL ? (
          <div className="flex flex-col justify-center items-center w-full max-w-4xl" ref={pdfContainerRef}>
            <button
              onClick={handleBackToList}
              className="mb-4 text-blue-500 flex items-center"
            >
              <FaChevronLeft className="mr-2" />
              Back to Magazines
            </button>

            <Document file={pdfURL} onLoadSuccess={handleDocumentLoadSuccess} className="border rounded-lg shadow-lg p-4 mb-4">
              <Page pageNumber={currentPage} width={550 * zoomLevel} />
            </Document>

            <div className="flex justify-between w-full max-w-4xl mt-4">
              <button
                onClick={goToPrevPage}
                className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage <= 1}
              >
                <FaArrowLeft color="white" />
              </button>

              <div className="flex items-center justify-center space-x-2">
                <span className="text-lg text-center">{currentPage} of {numPages}</span>
              </div>

              <button
                onClick={goToNextPage}
                className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 disabled:opacity-50"
                disabled={currentPage >= numPages}
              >
                <FaArrowRight color="white" />
              </button>
            </div>

            <div className="flex justify-center items-center mt-4 space-x-4">
              <button onClick={handleZoomIn} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaSearchPlus />
              </button>
              <button onClick={handleZoomOut} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaSearchMinus />
              </button>
              <button onClick={handleExpand} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaExpand />
              </button>
              <button onClick={handleDownloadPDF} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                <FaFilePdf />
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 mt-6 border border-gray-300">
            {magazines.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center">
                {magazines.map((magazine, index) => (
                  <div
                    key={index}
                    onClick={() => handleThumbnailClick(magazine.uploadYourFile)}
                    className="flex flex-col items-center bg-white rounded-lg shadow-lg cursor-pointer w-full max-w-xs transform transition-transform duration-300 hover:scale-105 mb-16"
                  >
                    <img
                      src={magazine.chooseYourThumbnail}
                      alt={`Magazine ${index}`}
                      className="w-full h-96 object-cover rounded-lg"
                    />
                    <p className="mt-4 text-lg font-medium">Magazine {index + 1}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="mt-4 text-lg font-medium">No magazines available.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthlyMagazine;
