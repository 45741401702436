import React from 'react';
import Header from '../GarmentLine/Header/Header';
import Footer from '../GarmentLine/Footer/Footer';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
  return (
    <div className="App">
      <Header />
      <div
        className="refund-policy-container"
        style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '40px' }} // Added marginTop for spacing
      >
        <div
          style={{
            padding: '30px',
            maxWidth: '850px',
            border: '2px solid #ccc',
            borderRadius: '10px',
            backgroundColor: '#f9f9f9',
            boxShadow: '2px 2px 10px rgba(0,0,0,0.1)',
          }}
        >
          <h1 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '32px' }}>Refund Policy</h1>
          <p style={{ fontSize: '18px', marginBottom: '24px', marginTop:'18px' }}>
            Thank you for choosing <strong>Connect Media.online</strong> Please read our refund policy carefully before making a purchase.
          </p>
          <h2 style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '24px' }}>No Refunds</h2>
          <p style={{ fontSize: '18px' }}>
            All payments made to <strong>ConnectMedia.online</strong> are <strong>final and non-refundable</strong>. Once a transaction is completed, we do not offer refunds, cancellations, or exchanges under any circumstances.
          </p>
          {/* <h2 style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '24px' }}>Exceptions</h2>
          <p style={{ fontSize: '18px' }}>Refunds will only be considered in cases of:</p>
          <ul style={{ fontSize: '18px' }}>
            <li>Duplicate payment due to a technical error.</li>
            <li>Unauthorized transactions (subject to verification).</li>
          </ul> */}
          {/* <p style={{ fontSize: '18px' }}>
            If you believe you qualify for an exception, please contact our support team at{' '}
            <Link to="https://www.garmentline.live" target="_blank" rel="noopener noreferrer">
              www.garmentline.live
            </Link>{' '}
            within <strong>7 days</strong> of the transaction.
          </p> */}
          <h2 style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '24px' }}>Modifications to Policy</h2>
          <p style={{ fontSize: '18px' }}>
            We reserve the right to modify or update this refund policy at any time. Any changes will be posted on this page.
          </p>
          <p style={{ fontSize: '18px' }}>
            For further assistance, please reach out to us at{' '}
            <strong>
              <Link to="https://connectmedia.online/" target="_blank" rel="noopener noreferrer">
                www.ConnectMedia.online
              </Link>
            </strong>
            .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
