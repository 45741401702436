import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login/Login';
import SubscribeNow from './Component/GarmentLine/SubscribeNow/SubscribeNow';
import Contact from './Component/GarmentLine/Contact/Contact';
import Blogs from './Component/GarmentLine/Blogs/Blogs';
import Magzines from './Component/GarmentLine/Magzines/Garmentline';
import Home from './Component/GarmentLine/Home/Home';
import ConnectMediaHome from './Component/MoreMagzines/ConnectMediaHome/ConnectMediaHome';
import ConnectMediaContact from './Component/MoreMagzines/ConnectMediaContact/ConnectMediaContact';
import ConnectMediaSubscribe from './Component/MoreMagzines/ConnectMediaSubscribe/ConnectMediaSubscribe';
import MonthlyMagazineG from './Component/MoreMagzines/ConnectMediaGarment/MonthlyMagazineG/MonthlyMagazineG';
import MagazineEpaperWeekly from './Component/MoreMagzines/ConnectMediaGarment/MagazineEpaperWeekly/MagazineEpaperWeekly';
import InnerFashionMagazine from './Component/MoreMagzines/ConnectMediaInnerFashion/InnerFashionMagazine/InnerFashionMagazine';
import InnerFashionWeekly from './Component/MoreMagzines/ConnectMediaInnerFashion/InnerFashionWeekly/InnerFashionWeekly';
import MedicalOnlineMagazine from './Component/MoreMagzines/MedicalOnline/MedicalOnlineMagazine/MedicalOnlineMagazine';
import MedicalOnlineEPaper from './Component/MoreMagzines/MedicalOnline/MedicalOnlineEPaper/MedicalOnlineEPaper';
import ConnectTermsCondition from './Component/MoreMagzines/ConnectTermsCondition/ConnectTermsCondition';
import Signup from './Component/SignUp/SignUp';
import Pricing from './Component/Pricing/Pricing';
import PrivacyPolicy from './Component/GarmentLine/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from './Component/TermsCondition/TermsCondition';
import RefundPolicy from './Component/RefundPolicy/RefundPolicy';
import Garmentline from './Component/GarmentLine/Magzines/Garmentline';
import Innerfashion from './Component/GarmentLine/Magzines/Innerfashion';
import Event from './Component/Event/Event';
import AboutUs from './Component/About Us/AboutUs';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/sign-up' element={<Signup />} />
        <Route path="/garmentline" element={<Garmentline />} />
        <Route path="/innerfashion" element={<Innerfashion />} />
        <Route path="/event" element={<Event />} />
        {/* <Route path='/e-paper-fortnightly' element={<EPaperFortnightly />} /> */}
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/subscribe' element={<SubscribeNow />} />
        <Route path='/terms-condition' element={<TermsCondition />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/blog' element={<Blogs />} />
        <Route path='/connect-media' element={<ConnectMediaHome />} />
        <Route path='/connect-media-contact' element={<ConnectMediaContact />} />
        <Route path='/connect-media-subscribe' element={<ConnectMediaSubscribe />} />
        <Route path='/monthly-magazine-g' element={<MonthlyMagazineG />} />
        <Route path='/weekly-magazine-g' element={<MagazineEpaperWeekly />} />
        <Route path='/monthly-magazine-i' element={<InnerFashionMagazine />} />
        <Route path='/weekly-magazine-i' element={<InnerFashionWeekly />} />
        <Route path='/monthly-magazine-m' element={<MedicalOnlineMagazine />} />
        <Route path='/weekly-magazine-m' element={<MedicalOnlineEPaper />} />
        <Route path='/connect-media-privacy-policy' element={<ConnectTermsCondition />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
