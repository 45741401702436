import React, { useState, useEffect, useRef } from "react";
import { FaArrowRight, FaArrowLeft, FaSearchPlus, FaSearchMinus, FaExpand, FaFilePdf, FaChevronLeft } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import ConnectMediaHeader from "../../ConnectMediaHeader/ConnectMediaHeader";
import Cookies from "js-cookie"; // Import js-cookie to manage cookies
import { useNavigate } from 'react-router-dom';
const MedicalOnlineMagazine = () => {
  const [magazines, setMagazines] = useState([]);
  const [pdfURL, setPdfURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [selectedMagazine, setSelectedMagazine] = useState(null); // Define selectedMagazine state
  const [showLoginPopup, setShowLoginPopup] = useState(false); // State for login popup
  const pdfContainerRef = useRef(null);
   const navigate = useNavigate();
  // PDF.js worker source
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const response = await fetch("https://garment-line-backend-0pol.onrender.com/apis/user/getAllMagazineMedicalOnline");
        const data = await response.json();
        setMagazines(data.data);
      } catch (error) {
        console.error("Error fetching magazines:", error);
      }
    };

    fetchMagazines();
  }, []);

  const handleThumbnailClick = (fileUrl, title) => {
    const accessToken = Cookies.get("accessToken"); // Check if accessToken exists in cookies
    if (!accessToken) {
      setShowLoginPopup(true); // If token is missing, show login popup
      return;
    }
    setPdfURL(fileUrl);
    setSelectedMagazine(title); // Ensure this is used for setting the magazine
    setCurrentPage(1); // Reset to the first page
  };

  const handleLoginRedirect = () => {
    setShowLoginPopup(false);
    navigate('/login'); // Redirect to the login page
  };

  const handleClosePopup = () => {
    // Close the login popup when the user clicks Cancel
    setShowLoginPopup(false);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleBackToList = () => {
    setPdfURL(null);
    setSelectedMagazine(null);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Zoom in by 0.1, max 2x
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Zoom out by 0.1, min 0.5x
  };

  const handleExpand = () => {
    if (pdfContainerRef.current) {
      const pdfContainer = pdfContainerRef.current;
      if (!document.fullscreenElement) {
        if (pdfContainer.requestFullscreen) {
          pdfContainer.requestFullscreen();
        } else if (pdfContainer.mozRequestFullScreen) { // Firefox
          pdfContainer.mozRequestFullScreen();
        } else if (pdfContainer.webkitRequestFullscreen) { // Chrome, Safari, Opera
          pdfContainer.webkitRequestFullscreen();
        } else if (pdfContainer.msRequestFullscreen) { // IE/Edge
          pdfContainer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }
    }
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = "magazine.pdf";
    link.click();
  };

  return (
    <div className="App">
      {/* ConnectMediaHeader Added */}
      <ConnectMediaHeader />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center">
        {/* Header Section */}
        <div className="text-center py-6 bg-gray-800 text-white mb-8 w-full">
          <h1 className="text-4xl font-bold">Medical Online Magazine</h1>
          <p className="text-xl">Explore the latest editions of our monthly magazines</p>
        </div>

        {/* Main Content Section */}
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 mt-6 border border-gray-300">
          {pdfURL ? (
            <div className="flex flex-col justify-center items-center" ref={pdfContainerRef}>
              <button
                onClick={handleBackToList}
                className="mb-4 text-blue-500 flex items-center"
              >
                <FaChevronLeft className="mr-2" />
                Back to Magazines
              </button>

              {/* PDF Viewer */}
              <Document file={pdfURL} onLoadSuccess={handleDocumentLoadSuccess} className="border rounded-lg shadow-lg p-4 mb-4">
                <Page pageNumber={currentPage} width={550 * zoomLevel} />
              </Document>

              {/* Navigation Controls */}
              <div className="flex justify-between w-full mt-4">
                <button onClick={goToPrevPage} disabled={currentPage <= 1} className="bg-blue-500 text-white py-2 px-4 rounded-full">
                  <FaArrowLeft />
                </button>
                <span className="text-lg">{currentPage} of {numPages}</span>
                <button onClick={goToNextPage} disabled={currentPage >= numPages} className="bg-blue-500 text-white py-2 px-4 rounded-full">
                  <FaArrowRight />
                </button>
              </div>

              {/* Zoom and other buttons */}
              <div className="flex justify-center items-center mt-4 space-x-4">
                <button onClick={handleZoomIn} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                  <FaSearchPlus />
                </button>
                <button onClick={handleZoomOut} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                  <FaSearchMinus />
                </button>
                <button onClick={handleExpand} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                  <FaExpand />
                </button>
                <button onClick={handleDownloadPDF} className="bg-gray-300 p-2 rounded-full hover:bg-gray-400">
                  <FaFilePdf />
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-16">
              {magazines.map((magazine) => (
                <div
                  key={magazine.id}
                  onClick={() => handleThumbnailClick(magazine.uploadYourFile, magazine.mediaName)}
                  className="flex flex-col items-center bg-white rounded-lg shadow-lg cursor-pointer mb-8"
                >
                  <img
                    src={magazine.uploadYourThumbnail}
                    alt={magazine.mediaName}
                    className="w-full h-96 object-cover rounded-lg"
                  />
                  <p className="mt-4 text-lg font-medium">{magazine.mediaName}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Login Popup */}
      {showLoginPopup && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Login Required</h2>
            <p className="mb-4">You need to log in to view this magazine.</p>
            <div className="flex justify-between">
              <button
                onClick={handleLoginRedirect}
                className="bg-blue-500 text-white py-2 px-4 rounded-full"
              >
                Login
              </button>
              <button
                onClick={handleClosePopup}
                className="bg-gray-300 text-black py-2 px-4 rounded-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalOnlineMagazine;
