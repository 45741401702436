import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const ConnectMediaHeader = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get('accessToken');
    setIsLoggedIn(!!token); // true if accessToken exists
  }, []);

  const handleLogout = () => {
    Cookies.remove("accessToken");  // Remove token from cookies
    console.log("User logged out, token removed.");
    setIsLoggedIn(false);  // Update state
    navigate("/login"); // Redirect to login page
  };

  const handleLogin = () => {
    navigate("/login");  // Redirect to login page
  };

  return (
    <div>
      <nav className="bg-gray-900 text-white py-3 md:py-4">
        <div className="container mx-auto flex flex-wrap justify-between items-center px-4">
          <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 text-center sm:text-left">
            <li className="sm:ml-8">
              <NavLink to="/Connect-media" className="hover:underline">
                <span className="mr-1">🏠</span>
              </NavLink>
            </li>
            <li className="sm:ml-8">
              <NavLink to="/monthly-magazine-g" className="hover:underline">
                GarmentLine
              </NavLink>
            </li>
            <li className="sm:ml-8">
              <NavLink to="/monthly-magazine-i" className="hover:underline">
                Inner Fashion 
              </NavLink>
            </li>
            <li className="sm:ml-8">
              <NavLink to="/monthly-magazine-m" className="hover:underline">
                Medical Online 
              </NavLink>
            </li>
            <li>
              <NavLink to="/connect-media-contact" className={({ isActive }) => isActive ? 'text-orange-500 hover:underline' : 'hover:underline'}>
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink to="/connect-media-subscribe" className={({ isActive }) => isActive ? 'text-orange-500 hover:underline' : 'hover:underline'}>
                Subscribe Now
              </NavLink>
            </li>
            <li>
              <NavLink to="/connect-media-privacy-policy" className={({ isActive }) => isActive ? 'text-orange-500 hover:underline' : 'hover:underline'}>
                Privacy Policy
              </NavLink>
            </li>
          </ul>
          <div className="relative w-full sm:w-1/3 lg:w-1/6 mt-3 sm:mt-0 flex space-x-3">
            <button className="text-white px-4 py-2 rounded border border-orange-500 hover:bg-orange-100 hover:text-orange-500 transition"
              onClick={() => navigate('/home')}>
              GarmentLine
            </button>
            {isLoggedIn ? (
              <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition" onClick={handleLogout}>
                Logout
              </button>
            ) : (
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition" onClick={handleLogin}>
                Login
              </button>
            )}
          </div>
        </div>
      </nav>

      <div className="relative w-full h-96 bg-cover bg-center"
        style={{ backgroundImage: 'url(assets/connectMediaBackground.png)', height: '250px' }}>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h3 className="text-4xl md:text-4xl font-bold text-center" style={{ color: '#FF1493', marginBottom: '-20px' }}>
            Connect Media
          </h3>
          <p className="text-lg md:text-xl text-white mt-5" style={{color: '#FFC0CB'}}>
            Your Gateway to Seamless Communication
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConnectMediaHeader;
