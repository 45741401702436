import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="App">
      <Header />
      <main className="container mx-auto py-8 px-4">
        <h2 className="text-3xl font-bold text-left mb-6 ml-6">Privacy Policy</h2>
        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Introduction </strong> –This Privacy Policy describes how Connect Media and its affiliates (collectively "Connect Media, we, our, us") collect, use, share, protect or otherwise process your information/personal data through our website team (hereinafter referred to as Platform). Please note that you may be able to browse certain sections of the Platform without registering with us. By visiting this Platform, providing your information or availing any service offered on the Platform, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Collection </strong> -We collect your personal data when you use our Platform, services or otherwise interact with us during the course of our relationship and related information provided from time to time. Some of the information that we may collect includes but is not limited to personal data/information provided to us during sign-up/registering or using our Platform such as name, date of birth, address, telephone/mobile number, email ID and/or any such information shared as proof of identity or address.
        </p>
        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Usage </strong> -We use personal data to provide the services you request. To the extent we use your personal data to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to assist sellers and business partners in enhancing customer experience; to resolve disputes; troubleshoot problems; inform you about online and offline offers, products, services, and updates.
        </p>

        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Security Precautions </strong> -To protect your personal data from unauthorised access or disclosure, loss or misuse we adopt reasonable security practices and procedures. Once your information is in our possession or whenever you access your account information, we adhere to our security guidelines to protect it against unauthorised access.
        </p>

        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Consent</strong> -By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information on the Platform in accordance with this Privacy Policy. If you disclose to us any personal data relating to other people, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy. You, while providing your personal data over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy. You have an option to withdraw your consent that you have already provided by writing to the Grievance Officer at the contact information provided below. Please mention "Withdrawal of consent for processing personal data" in your subject line of your communication. We may verify such requests before acting on our request. However, please note that your withdrawal of consent will not be retrospective and will be in accordance with the Terms of Use, this Privacy Policy, and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for which we consider such information to be necessary.
        </p>

        <p className="text-lg mb-4 ml-6 mr-6">
          <strong>Changes to this Privacy Policy</strong> -Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We may alert / notify you about the significant changes to the Privacy Policy, in the manner as may be required under applicable laws.
        </p>
        </main>
        <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
