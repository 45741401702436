import React from 'react';
import Header from '../Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Blogs = () => {
  return (
    <div className="App">
      <Header />
      <div className="px-6 py-8">
        <h1 className="text-3xl font-bold text-left mb-4 ml-8">
          Garment Industry B2B Blog
        </h1>
        <h1 className="text-3xl font-bold text-left mb-4 ml-8">
          Coronavirus Pandemic downturn strikes solid on
          <br />
          Indian Garment Industry
        </h1>
        <div className="flex items-start space-x-8 ml-8 mr-8">
          <div className="w-1/2">
            <img src="/assets/image2.jpg" alt="Garment Industry" className="w-full h-98"/>
          </div>
          <div className="w-1/2">
            <p className="text-lg">
              According to the latest report, the impact of the coronavirus
              pandemic on our Country’s Domestic Garment Industry (Manufacturers,
              Wholesalers, Retailers) has turned out unparallel. And, plunging sales,
              shutting down of factories, lower wages, etc… have added on to the problems.
            </p>

            <p className="text-lg mt-4">
              The Indian Domestic Garment Industry in India are once again faced with
              a huge challenge caused by the current wave-2 of COVID-19, with 77 per cent
              of the manufacturers surveyed contemplating reducing their staff by more
              than 25 per cent.
            </p>
            <p className="text-lg mt-4">
              The Domestic Garment Industry, which went through a torrid last year,
              was just about recovering at the beginning of 2021, reaching close to 80
              per cent of its pre-COVID sales levels.
            </p>
            <p className="text-lg mt-4">
              On the other hand, the CMAI Study, conducted at the beginning of May ’21, indicates 55 per cent of the Manufacturers, Wholesalers, and Retailers achieving less than 25 per cent of their sales during April 2021.
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-8 mt-8 ml-8 mr-8">
          <div className="w-1/2">
            <p className="text-lg">
              <strong>Uncertainty</strong> <br />is looming large for these Manufacturers, Wholesalers, and
              Retailers as 72 per cent of the respondents have seen more than 50 percent
              of their orders on hand being cancelled by their buyers. This will lead to
              a huge problem of dead inventory in the coming months.
            </p>

            <p className="text-lg mt-4">
              “The situation is equally grim on the cash flow front, with 72 percent of
              the respondents receiving less than 25 percent of their due payments in
              April, and another 12 percent receiving less than 50 percent of their dues,”
              it added.
            </p>

            <p className="text-lg mt-4">
              What makes the situation more worrying is that as a result of regional lockdowns increasing across the country, 90 percent of the members believe
              May to be far worse than April, and 45 percent not expecting markets to revive before Diwali festival-2021. In fact, more than 20 percent do not see the revival starting before 2022.
            </p>
          </div>
          <div className="w-1/2">
            <img src="/assets/image1.jpg" alt="Garment Industry Uncertainty" className="w-full h-auto"/>
          </div>
        </div>
        <div className="mt-8 text-black ml-8 mr-8">
          <p className="text-lg">
           <strong className='text-4xl'>W</strong>hile fully supporting the various State Governments in their measures to control the pandemic, industry agencies have urged the governments, in particular the governments of Kerala, Delhi, Maharashtra, and Karnataka – two of the largest clusters of garment manufacturing in the country – to give due consideration to the domestic garment industry as and when they contemplate the opening up of economic activity. CMAI has urged the government to permit garment factories to operate with 50 percent of their capacity with immediate effect, whilst strictly following COVID protocols.
          </p>
          
          <p className="text-lg mt-4">
           <strong>The Indian Domestic Garment</strong>  <br/>(Manufacturers, Wholesalers, Retailers) industry is staring at huge losses due to the COVID lockdown. The analyst community expects garment companies’ sales to go down anywhere between 50 percent and 60 percent in the first quarter of the financial year 2020-21.
          </p>

          <p className="text-lg mt-4">
            A key issue for the garment sector is that a large part of the Spring-Summer Collection has already arrived for most players. Inventory levels are high, but footfalls have dropped significantly. In certain cases, the drop is as high as 80-100 percent.
          </p>

          <p className="text-lg mt-4">
            In fact, most garment brands have stopped orders of the Fall-Winter Collection as they know that they would end up with excess inventory from the previous collection. “We were expecting 50 percent sales in April-May and we were expecting it to spike from thereon. I don’t expect more than 10 percent sales in April,” says Lalit Agrawal, MD of value fashion brand, V-Mart.
          </p>

          <p className="text-lg mt-4">
            Kavi Mishra, the Managing Director, and CEO of House Of Anita Dongre says that his company has cut down on fall-winter collection production. “We will spread our spring-summer collection over the next few months, as that will help us protect cash flows.”
          </p>

          <p className="text-lg mt-4">
            Preeta Sukhtankar, Founder, Label Life, says that her brand is doing whatever it could to bring in positivity at a time when the mood is somber due to the outbreak of the deadly COVID-19. Sukhtankar of Label Life says that the saving grace for her brand is the strategy of launching a new collection every month. “We thankfully don’t have too much inventory as we follow a practice of launching a new collection every month. Our April collection hasn’t come yet. However, this month, I am not sure when our products will reach the consumer,” she says, referring to the COVID-19 lockdown, due to which brands are unable to do online deliveries.
          </p>
          <p className="text-lg mt-4">
            Mishra of House Of Anita Dongre, says that the biggest lesson from COVID-19 for Garment brands like his has been to move to a more flexible supply chain model. “We usually plan our collection for a period of six months, we now need to plan a new collection every two months and have at least 6-7 collections a year. That’s how we can manage our inventory more efficiently.”
          </p>
          <p className="text-lg mt-4">
          Garment brands, says Mr. Rishav Jain of Alvarez & Marsal, are trying all possible ways of generating funds. “Retailers and brands are trying to manage vendor payments cycles, discussing delay in rental payouts with landlords, trying to stop all kinds of frill expenses. Most players are also trying to use this opportunity to rationalize their underperforming stores as well.”
          </p>
          <p className="text-lg mt-4">
          Both Agrawal of V-Mart and Misra of House Of Anita Dongre, have confirmed that all their store openings in the coming months have been halted. While the 300-store strong House Of Anita Dongre was planning 10 percent increase in its store count, V-Mart was planning to open as many as eight stores in the next two months.
          </p>
          <p className="text-lg mt-4">
            While most of the leading Garment brands have promised to pay their store staff for the next 40 days, if the COVID-19 lockdown extends beyond that, they are not sure if they will be able to protect their salaries.
          </p>
          <p className="text-lg mt-4">
           The <Link to="/inner-fashion" className="text-orange-500 hover:underline">ladies garment</Link> as well as female garment, ladys garment  & kids wear, children wear, boys wear, girls wear, ethnic wear, and last but not the least men’s wear .
          </p>
          <p className="text-lg mt-4">
            <strong>The full recovery for Indian apparel –</strong> <br/>players is likely to be delayed to 2022-23 due to the resurgence of the COVID-19 pandemic cases, according to a report. ICRA Ratings expects the full recovery for Indian domestic <Link to="/connect-media" className="text-orange-500 hover:underline">garment</Link> (manufacturers / Wholesalers & Retailers) players to be prolonged and pushed back to the financial year 2023 amid rising COVID cases in India and some of the key markets.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Blogs;
