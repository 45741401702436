import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const navigate = useNavigate();

  const handleSendOtp = async () => {
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      alert('Please enter a valid 10-digit mobile number.');
      return;
    }

    if (otpLoading) return;
    setOtpLoading(true);

    const newPhoneNumber = `+91${phoneNumber}`;


    try { 
      const formattedPhoneNumber = newPhoneNumber ? (newPhoneNumber.startsWith('+91') ? newPhoneNumber : `+91${newPhoneNumber}`) : null;

      if (!formattedPhoneNumber) {
        console.error("Error: Phone number is missing!");
        return;
      }

      console.log(formattedPhoneNumber);

      const response = await fetch('https://garment-line-backend-0pol.onrender.com/apis/userAuth/sendOtpSignUp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"phoneNumber": formattedPhoneNumber}),
      });

      const data = await response.json();
      console.log("Data:", data)
      if (response.ok) {
        setOtpSent(true);
        setShowOtpModal(true);
        setUserId(data.data.userId);
        alert('OTP sent successfully.');
      } else {
        alert(data.message || 'Failed to send OTP. Try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP.');
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    console.log("OTP:", otp);
    console.log("UserID:", userId);
    if (!otp || !userId) {
      alert('Invalid OTP or missing user ID.');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch('https://garment-line-backend-0pol.onrender.com/apis/userAuth/verifyOtpSignUp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, otp }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpVerified(true);
        setShowOtpModal(false);
        alert('OTP verified successfully.');
      } else {
        alert(data.message || 'Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!otpVerified) {
      alert('Please verify your mobile number first.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://garment-line-backend-0pol.onrender.com/apis/userAuth/createAccount', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, companyName, password }),
      });

      const data = await response.json();
      if (response.ok) {
        navigate('/');
      } else {
        alert(`Signup failed: ${data.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Signup failed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  // console.log(otpVerified);

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/phoneTemplate.jpg')" }}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-2xl font-semibold text-left mb-2">Sign Up</h2>
        <form onSubmit={handleSignup}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
              Company Name
            </label>
            <input 
              type="text" 
              id="companyName" 
              value={companyName} 
              onChange={(e) => setCompanyName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required 
              placeholder="Enter your company name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
              Mobile Number
            </label>
            <div className="flex items-center border rounded-lg px-3 py-2">
              <span className="text-gray-700">+91</span>
              <input type="text"id="phoneNumber"value={phoneNumber}onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full ml-2 outline-none"requiredplaceholder="Enter your mobile number"disabled={otpSent}
              />
              <button  type="button"className={`ml-2 text-sm text-red-500 ${otpLoading ? 'opacity-50 cursor-not-allowed' : ''}`}onClick={handleSendOtp}
              disabled={otpSent || otpLoading}
              >
                {otpLoading ? 'Sending...' : 'Send OTP'}
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input 
              type="password" 
              id="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required 
              placeholder="Enter your password"
            />
          </div>
          <button
            type="submit"
            className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none ${loading || !otpVerified ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading || !otpVerified}
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>

          <div className="text-left mt-2 text-sm">
            Already have an account? <Link to="/login" className="text-blue-500 hover:underline">Login</Link>
          </div>
        </form>
      </div>

      {showOtpModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 text-center">
            <h3 className="text-lg font-semibold mb-2">Enter OTP</h3>
            <input 
              type="text" 
              value={otp} 
              onChange={(e) => setOtp(e.target.value)} 
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4" 
              placeholder="Enter OTP" 
            />
            <button 
              onClick={handleVerifyOtp} 
              className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading || !otp}
            >
              {loading ? 'Verifying...' : 'Verify OTP'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
